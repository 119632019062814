import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../header/headershop";
import ProductWrap from "../../src/product/productWrap";
import MobileMenu from "../../src/navbarHead/mobileNav";
import noimage from '../../src/images/noimage.jpg';
import SellerProfileDetails from "../component";
import Topimage from '../../src/images/store/top-image.jpg';

const SellerProduts = () => {
    const token = localStorage.getItem("token");
    const { sellerid } = useParams();
    const [itemsForSeller, setItemsForSeller] = useState([]);
    const [itemsForSellerforClient, setItemsForSellerforClientDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorvalue, setErrorvalue] = useState(null);
    const [errorvalueforimg, setErrorvalueforimg] = useState(null);
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    useEffect(() => {
        const fetchProductsBySeller = async () => {
            try {
                setLoading(true);
                const response = await axios.put(
                    'https://api.starmallonline.com/api/v1/product/getbyseller',
                    { sellerid: sellerid, currency: localStorage.getItem('currencyName') },
                );
                if (response.status === 200) {
                    const items = response.data;
                    setItemsForSeller(items);
                }
                if(response.data.status === "Error"){
                    setErrorvalue(response.data.description);
                    setErrorvalueforimg(response.data.organiserId);
                }
            } catch (error) {
                console.error("Error:", error);
            }finally {
                setLoading(false);
            }
        };

        fetchProductsBySeller();
    }, [sellerid, token]);

    useEffect(() => {
        const fetchProductsBySellerforClienTDetails = async () => {
            try {
                const response = await axios.put(
                    'https://api.starmallonline.com/api/v1/seller/sellerdetails/getforclient',
                    { sellerid: sellerid, currency: localStorage.getItem('currencyName') },
                );
                if (response.status === 200) {
                    const items = response.data;
                    setItemsForSellerforClientDetails(items);
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchProductsBySellerforClienTDetails();
    }, [sellerid, token]);

    return (
        <div>
            <Ecomheader />
            <MobileMenu />
            <div className="container-fluied seller-bg" style={{ 
            backgroundImage: `url(${Topimage})`, }}>
            <div className="container">
            <SellerProfileDetails sellerDetailsforclient={itemsForSellerforClient} />
            </div>

            </div>
            <div className="container homebg seller-profile">
            {itemsForSellerforClient && (
            <div>
                {/* <img src={noimage}   alt={itemsForSellerforClient.sellerid}/>
                <p>Seller ID: {itemsForSellerforClient.sellerid}</p>
                <p>Seller Code: {itemsForSellerforClient.sellercode}</p>
                <p>noofproducts: {itemsForSellerforClient.noofproducts}</p>
                <p>noofcategory: {itemsForSellerforClient.noofcategory}</p>
                <p>joiningdate1: {itemsForSellerforClient.joiningdate1}</p>
                <p>joiningdate: {itemsForSellerforClient.joiningdate}</p> */}
            </div>
        )}
         {loading || !itemsForSeller  ?(
                   <div className="container-fluied">
                   <div className="row">
                     <div className="loader"></div>
                   </div>
                 </div>
                ) : (
                    <>
       

     
                    <div className="row">
                    {itemsForSeller.length !== 0 ? ( 
                        itemsForSeller.map((item) => (
                            // <div key={item.productid} className="col-xs-6 col-sm-2 col-lg-2">
                                <ProductWrap key={item.productid} product={item} />
                            // </div>
                        ))
                    ) : (
                        <h1 className="notavailable" style={{ display: !itemsForSeller ? 'none' : 'block' }}>
        <img src={errorvalueforimg}/>
        {errorvalue}
      </h1>
                    )}
                    </div>
                    </>
                )}
            </div>
            <Ecomfooter />
        </div>
    );
};

export default SellerProduts;
