import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import MobileMenu from "../../src/navbarHead/mobileNav";
const AccountOrders = () => {
  const [orders, setOrders] = useState([]);
  const [returnOrder, setReturnOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    // Function to fetch order data
    const fetchOrders = async () => {
      try {
        // Make the API request to get order data
        const response = await fetch("https://api.starmallonline.com/api/v1/order/get", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const sortedOrders = data.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
          // Set the retrieved order data in state
          setOrders(sortedOrders);
        } else {
          console.error("Failed to get order data");
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    // Call the fetchOrders function when the component mounts
    fetchOrders();
  }, []); // Empty dependency array ensures this effect runs once

  useEffect(() => {
    const returnedProducts = async () => {
      try {
        const response = await fetch("https://api.starmallonline.com/api/v1/order/getreturnedbyidandclientid", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const returnData = await response.json();
          setReturnOrder(returnData);
        } else {
          console.error("Failed to get order data");
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };
    returnedProducts();
  }, []);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(orders.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset current page when the search query changes
  };
  const filteredOrders = orders.filter(order => order.ordernumber.includes(searchQuery));

const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
useEffect(() => {
  setSearchQuery("");
}, [currentPage]);
  return (
    <div>
      <Ecomheader />
      <MobileMenu/>
        <main className="main account container homebg order-datatable">
          <nav className="breadcrumb-nav">
            <div className="container">
              <ul className="breadcrumb">
              </ul>
            </div>
          </nav>
          <div className="page-content pt-2">
            <div className="">
              <h2 className="title title-center">My Orders</h2>
              <div className="row">
              <div className="tab-content col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex justify-content-end">
  <input
    type="text"
    id="searchInput"
    className="form-control col-md-4 col-sm-12"
    value={searchQuery}
    onChange={handleSearchChange}
    placeholder="Search by Order Number"
  />
</div></div></div>
              <div className="tab tab-vertical">
                
                <div className="tab-content col-lg-12 col-md-12">
                  <div classNamename="orders">
                    <div className="tab-pane" id="orders">
                      <table className="order-table">
                        <thead>
                          <tr>
                            <th className="pl-2">Order</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Total</th>
                            <th>Payment Status</th>
                            <th className="pr-2">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentOrders.map((order) => (
                            <tr key={order.orderid}>
                              <td className="order-number">
                                <a href={"/ViewOrder/"+order.orderid}>
                                  {order.ordernumber}</a>
                              </td>
                              <td className="order-date">
                                {new Date(order.createDate).toISOString().split('T')[0]}
                              </td>
                              <td className="order-status">
                                <span>{order.orderStatus}</span>
                              </td>
                              <td className="order-total">
                                <span>{order.total}</span>
                              </td>
                              <td className="order-total">
                                <span>{order.paymentstatus}</span>
                              </td>
                              <td className="order-action">
                                <Link to={`/ViewOrder/${order.orderid}`} className="btn btn-primary btn-link btn-underline">
                                  View
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pagination" style={{ textAlign: "end", display: "flex", justifyContent: "flex-end" }}>
  <button
    className="page-link mr-2"
    onClick={() => setCurrentPage(currentPage - 1)}
    disabled={currentPage === 1}
  >
    <FontAwesomeIcon icon={faChevronLeft} />  
  </button>
  
  <span className="page-link mx-2">{currentPage}</span>

  <button
    className="page-link ml-2"
    onClick={() => setCurrentPage(currentPage + 1)}
    disabled={currentPage === Math.ceil(orders.length / itemsPerPage)}
  >
      <FontAwesomeIcon icon={faChevronRight} />
  </button>
</div>
            </div>
          </div>
        </main>
      <Ecomfooter />
    </div>

  )
}

export default AccountOrders;