import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";
import { Modal, Form, Input, Button } from "antd";
import { Helmet } from "react-helmet";
import MobileMenu from "../../src/navbarHead/mobileNav";
const AddressAccount = () => {
  const [clientid, setClientid] = useState("");
  const [clientDetails, setClientDetails] = useState({});
  const [shippingAddress, setShippingAddress] = useState("");
  const [editAddress, setEditAddress] = useState({});
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [clientaddressid, setclientaddressid] = useState("");
  const [responseBillingAddress, setResponseBillingAddress] = useState(false);
  const [form] = Form.useForm();
  const fetchBillingAddress = async (id) => {
    try {
      // Define the payload with the client ID
      const payload = {
        clientid: id,
      };

      // Make the API request to get billing address with the payload
      const response = await fetch(
        "https://api.starmallonline.com/api/v1/client/getbillingaddress",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        // Handle the received billing address data as needed
        if (data && data!=null) {
          setResponseBillingAddress(data);
        } else {
          setResponseBillingAddress(false);
        }
      } else {
        console.error("Failed to get billing address");
      }
    } catch (error) {
      console.error("Error fetching billing address:", error);
    }
  };
  useEffect(() => {
    // Function to fetch client details
    const fetchClientDetails = async () => {
      try {
        // Make the API request to get client details
        const response = await fetch(
          "https://api.starmallonline.com/api/v1/login/getclientdetails",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          // Set the client details in state
          setClientDetails(data);
          setClientid(data.id);
          // After receiving client details, make another API request
          // to fetch address billing using the id from the client details
          fetchAddressBilling(data.id);
          fetchBillingAddress(data.id);
        } else {
          console.error("Failed to get client details");
        }
      } catch (error) {
        console.error("Error fetching client details:", error);
      }
    };

    // Function to fetch address billing
    const fetchAddressBilling = async (id) => {
      try {
        // Define the payload with the client ID
        const payload = {
          clientid: id,
        };

        // Make the API request to get address billing with the payload
        const response = await fetch(
          "https://api.starmallonline.com/api/v1/client/getaddress",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(payload),
          }
        );

        if (response.ok) {
          const data = await response.json();
          // Set the address billing data in state
          setShippingAddress(data);
        } else {
          console.error("Failed to get address billing");
        }
      } catch (error) {
        console.error("Error fetching address billing:", error);
      }
    };

    // Call the fetchClientDetails function when the component mounts
    fetchClientDetails();
  }, []);
  // Function to open the edit popup
  const openEditPopup = (shippingAddress) => {
    setEditAddress(shippingAddress);
    setIsEditPopupOpen(true);
    form.setFieldsValue({
      clientid: shippingAddress.clientid,
      clientaddressid: shippingAddress.clientaddressid,
      name: shippingAddress.name,
      companyname: shippingAddress.companyname,
      country: shippingAddress.country,
      address1: shippingAddress.address1,
      address2: shippingAddress.address2,
      city: shippingAddress.city,
      state: shippingAddress.state,
      pincode: shippingAddress.pincode,
      phonenumber: shippingAddress.phonenumber,
      email: shippingAddress.email,
      region: shippingAddress.region,
    });
  };

  // Function to close the edit popup
  const closeEditPopup = () => {
    setIsEditPopupOpen(false);
    form.resetFields();
  };
  const onFinish = async (values) => {
    setError(null);

    try {
      setLoading(true);
      // TODO: Replace with actual API call
      const response = await fetch(
        "https://api.starmallonline.com/api/v1/client/addaddress",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            clientaddressid: clientaddressid,
            ...values,
          }),
        }
      );

      if (response.status === 401 || response.status === 404) {
        setError("Unauthorized or Not Found");
        setLoading(false);
        return;
      }

      if (response.ok) {
        const responseData = await response.json();
        setData(responseData.data);
        setLoading(false);
        closeEditPopup(); // Close the popup after successful submission
        window.location.reload();
      } else {
        setError("Failed to add address");
        setLoading(false);
      }
    } catch (error) {
      setError("An error occurred");
      setLoading(false);
    }
  };

  return (
    <div>
    <div>
        <Helmet>
            <title>Address</title>
        </Helmet>
    </div>
      <Ecomheader />
      <MobileMenu/>
      <main className="main account">
        <nav className="breadcrumb-nav">
          <div className="container ">
            <ul className="breadcrumb"></ul>
          </div>
        </nav>
        <div className="page-content mt-4 mb-10 pb-6">
          <div className="container homebg">
            <h2 className="title title-center mb-10">My Account</h2>
            <div className="tab tab-vertical gutter-lg">
              {/* <ul
                className="nav nav-tabs mb-4 col-lg-3 col-md-4"
                role="tablist"
              >
                <div className="nav-item">
                  <Link to="/AccountOrders" className="nav-link">
                    Orders
                  </Link>
                </div>
                <div className="nav-item">
                  <Link to="/AddressAccount" className="nav-link">
                    Address
                  </Link>
                </div>
                <div className="nav-item">
                  <Link to="/AccountDetails" className="nav-link">
                    Account Details
                  </Link>
                </div>
              </ul> */}
              <div className="tab-content col-lg-9 col-md-8">
                <div className="address">
                  <div className="tab-pane" id="address">
                    <p className="mb-2">
                      The following addresses will be used on the checkout page
                      by default.
                    </p>
                    <div className="row">
                      <div className="col-sm-6 mb-4">
                        <div className="card card-address">
                        <div className="card-body">
  <h5 className="card-title text-uppercase">Billing Address</h5>
  {responseBillingAddress ? (
    <>
      <p>{responseBillingAddress.name}</p>
      <p>{responseBillingAddress.address1}</p>
      <p>{responseBillingAddress.address2}</p>
      <p>{responseBillingAddress.city}</p>
      <p>{responseBillingAddress.state}</p>
      <p>{responseBillingAddress.pincode}</p>
      <p>{responseBillingAddress.phonenumber}</p>
      {/* Include other billing address details here */}
      <Link to="/Billing" className="btn btn-link btn-secondary btn-underline">
        Edit <i className="far fa-edit" />
      </Link>
    </>
  ) : (
    <Link to="/Billing" className="btn btn-link btn-secondary btn-underline">
      Add <i className="far fa-edit" />
    </Link>
  )}
</div>

                        </div>
                      </div>
                      <div className="col-sm-6 mb-4">
                        <div className="card card-address">
                          <div className="card-body">
                            <h5 className="card-title text-uppercase">
                              Shipping Address
                            </h5>
                            {shippingAddress &&
                              shippingAddress.map((shippingAddress, index) => (
                                <div key={index}>
                                  <p>
                                   {shippingAddress.name},{" "}
                                    {shippingAddress.companyname}, {" "}
                                    {shippingAddress.country}, {" "}
                                    {shippingAddress.region}, {" "}
                                    {shippingAddress.address1}, {" "}
                                    {shippingAddress.address2},{" "}
                                    {shippingAddress.city},{" "}
                                    {shippingAddress.state},{" "}
                                    {shippingAddress.pincode}, {" "}
                                    {shippingAddress.phonenumber},  {" "}
                                    {shippingAddress.email}
                                  </p>
                                  <button
                                    className="btn btn-link btn-secondary btn-underline"
                                    onClick={() =>
                                      openEditPopup(shippingAddress)
                                    }
                                  >
                                    Edit <i className="far fa-edit"></i>
                                  </button>
                                </div>
                              ))}
                              <Link
                            to="/ShippingForm"
                            className="btn btn-link btn-secondary btn-underline" style={{display:"flex",alignItems:"center",justifyContent:"center",marginLeft:"40%"}}
                          >
                            ADD<i className="far fa-edit"></i>
                          </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {isEditPopupOpen && (
        <Modal
          title="Edit Shipping Address"
          visible={isEditPopupOpen}
          onCancel={closeEditPopup}
          footer={[
            <Button key="cancel" onClick={closeEditPopup}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              form="edit-form"
            >
              Save
            </Button>,
          ]}
        >
          <Form form={form} id="edit-form" onFinish={onFinish}>
          <Form.Item style={{display:"none"}} label="clientid" name="clientid">
              <Input type="text" />
            </Form.Item>
          <Form.Item style={{display:"none"}} label="region" name="region">
              <Input type="text" />
            </Form.Item>
          <Form.Item style={{display:"none"}} label="clientaddressid" name="clientaddressid">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Name" name="name">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Company Name" name="companyname">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Country" name="country">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Address 1" name="address1">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Address 2" name="address2">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="City" name="city">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="State" name="state">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Pincode" name="pincode">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Phone Number" name="phonenumber">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input type="text" />
            </Form.Item>
          </Form>
        </Modal>
      )}
      <Ecomfooter />
    </div>
  );
};

export default AddressAccount;
