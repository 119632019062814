import CryptoJS from 'crypto-js';

class AesCryptUtil {
  constructor(encryptionKey) {
    this.encryptionKey = encryptionKey;
  }

  encrypt(data) {
    const encryptedData = CryptoJS.AES.encrypt(data, this.encryptionKey).toString();
    return encryptedData;
  }

  decrypt(encryptedData) {
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, this.encryptionKey).toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }
}

export default AesCryptUtil;
