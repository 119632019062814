import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";
import { Helmet } from "react-helmet";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MobileMenu from "../../src/navbarHead/mobileNav";
const ProfileChange=()=>{
       const navigate = useNavigate();
        const [clientDetails, setClientDetails] = useState({});
        const [mobilenumber,setMobilenumber] = useState('');
        const [currentpassword,setcurrentpassword] = useState('');
        const [newpassword,setnewpassword] = useState('');
        const [confirmpassword,setconfirmpassword] = useState('');
        const [mobileotp,setMobileotp] = useState('');
        const [mobileotpverify,setMobileotpVerify] = useState(false);
        const [passwordotpverify,setpasswordotpVerify] = useState(false);
        const [passotp,setpassotp] = useState(false);
        const [errorMessage, setErrorMessage] = useState('');
        const [passwordChangeResponse, setPasswordChangeResponse] = useState(null);
        const [passwordMatch, setPasswordMatch] = useState(true);
        const [showCurrentPassword, setCurrentShowPassword] = useState(false);
        const [showNewPassword, setNewShowPassword] = useState(false);
        const [showConfirmPassword, setConfirmShowPassword] = useState(false);
        const [responseMessage, setResponseMessage] = useState("");

  const toggleCurrentPasswordVisibility = () => {
    setCurrentShowPassword(!showCurrentPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setNewShowPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };
        const [editedClientDetails, setEditedClientDetails] = useState({
                firstname: "",
                lastname: "",
              });
        const token = localStorage.getItem("token");
      
        useEffect(() => {
          const fetchClientDetails = async () => {
            try {
              const response = await fetch("https://api.starmallonline.com/api/v1/login/getclientdetails", {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
      
              if (response.ok) {
                const data = await response.json();
                setClientDetails(data);
                setMobilenumber(data.mobile || '');
                 // Initialize editedClientDetails with clientDetails values
          setEditedClientDetails({
            firstname: data.firstname,
            lastname: data.lastname,
          });
              } else {
                console.error("Failed to get client details");
              }
            } catch (error) {
              console.error("Error fetching client details:", error);
            }
          };
      
          fetchClientDetails();
        }, [token]);

        const handleEditClientDetails = async () => {
                try {
                  const response = await fetch("https://api.starmallonline.com/api/v1/client/editClientDetails", {
                    method: "POST",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      clientid: clientDetails.id,
                      firstname: editedClientDetails.firstname,
                      lastname: editedClientDetails.lastname,
                    }),
                  });
            
                  if (response.ok) {
                    // The client details have been updated successfully
                    // You can handle success actions here
                    // Optionally, you can update clientDetails state with the edited values
                    setClientDetails((prevClientDetails) => ({
                      ...prevClientDetails,
                      ...editedClientDetails,
                    }));
                      
          setResponseMessage(response.data.description);
                  } else {
                    console.error("Failed to update client details");
                    // Handle the error here
                  }
                } catch (error) {
                  console.error("Error updating client details:", error);
                  // Handle the error here
                }
              };
              const sendOtptoMobile = async () => {
                if (mobilenumber.length < localStorage.getItem('mobilemaxlength')) {
                  // Show an error message for less than 10 digits
                  setErrorMessage('Please enter ' + localStorage.getItem('mobilemaxlength') + ' digits.');

                  return;
                }
                try {
                  const response = await fetch("https://api.starmallonline.com/api/v1/client/mobileotpclient", {
                    method: "PUT",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      mobile: mobilenumber,
                    }),
                  });
            
                  if (response.ok) {
                    setMobileotpVerify(true)
                  } else {
                    console.error("Failed to send OTP");
                    // Handle the error here
                  }
                } catch (error) {
                  console.error("Error sending OTP:", error);
                  // Handle the error here
                }
              };
              const verifyOtptoMobile = async () => {
                try {
                  const response = await fetch("https://api.starmallonline.com/api/v1/client/mobilechange", {
                    method: "POST",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      mobile: mobilenumber,
                      otp: mobileotp.mobileotp,
                    }),
                  });
            
                  if (response.ok) {
                    alert("Mobile number changed successfully")
                    window.location.reload();
                  } else {
                    console.error("Failed to send OTP");
                    // Handle the error here
                  }
                } catch (error) {
                  console.error("Error sending OTP:", error);
                  // Handle the error here
                }
              };
              
              const sendOtptoMobileforpassword = async () => {
                if (newpassword.newpassword !== confirmpassword.confirmpassword) {
                  setPasswordMatch(false);
                  return;
                }
                try {
                  const response = await fetch("https://api.starmallonline.com/api/v1/client/mobileotpclient", {
                    method: "PUT",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      mobile: clientDetails.mobile,
                      
                    }),
                  });
            
                  if (response.ok) {
                    setpasswordotpVerify(true)
                  } else {
                    console.error("Failed to send OTP");
                    // Handle the error here
                  }
                } catch (error) {
                  console.error("Error sending OTP:", error);
                  // Handle the error here
                }
              };
              
              const verifyOtptoMobileforpassword = async () => {
                try {
                  const response = await fetch("https://api.starmallonline.com/api/v1/client/passwordchange", {
                    method: "Put",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      mobile: clientDetails.mobile,
                      otp:passotp.passotp,
                      oldpassword: currentpassword.currentpassword,
                      newpassword:newpassword.newpassword,
                    }),
                  });
            
                  if (response.ok) {  
                    setPasswordChangeResponse("Password Changed Successfully");
                    setpasswordotpVerify(true);
                   navigate(-1);
                  } else {
                    console.error("Failed to send OTP");
                    // Handle the error here
                  }
                } catch (error) {
                  console.error("Error sending OTP:", error);
                  // Handle the error here
                }
              };
              const handleMobileChange = (e) => {
                const newValue = e.target.value;
            
                // Check if the input consists of only digits and has a maximum length of 10 characters
                if (/^\d*$/.test(newValue) && newValue.length <= parseInt(localStorage.getItem('mobilemaxlength')))  {
                  setMobilenumber(newValue);
                  setErrorMessage('');
        
                }else {
                  // Set an error message when the input is invalid
                  setErrorMessage('Phone number must be a maximum of'  + localStorage.getItem('mobilemaxlength') + 'digits and contain only numbers.');
                }
              };
return(
  <div>
        <div>
            <Helmet>
                <title>Account</title>
            </Helmet>
        </div>
        <Ecomheader/>
        <MobileMenu/>
<main class="main account container homebg">
<nav class="breadcrumb-nav">
<div class="container">
<ul class="breadcrumb">
</ul>
</div>
</nav>
<div class="">
<div class="">

<div class="container-fluied  pt-5">
   
<div className="row  d-flex justify-content-center  align-self-center  registration-login">
<div class="col-lg-6 col-md-6 col-sm-12 form-box-for-border">
<div class="form-box-header">
<h2 >Edit Profile </h2>
</div>
<div classname="accountdetails">    
<div class="tab-pane" id="account">
<form action="#" class="form">
 <div class="row">
<div class="col-md-6 col-sm-12  mt-3">
<label>Name *</label>
<input type="text" class="form-control" name="first_name" required  value={editedClientDetails.firstname}  onChange={(e) =>
                              setEditedClientDetails({
                                ...editedClientDetails,
                                firstname: e.target.value,
                              })
                            }/>
</div>
<div class="col-md-6 col-sm-12  mt-3">
<label>Display Name *</label>
<input type="text" class="form-control mb-0" name="display_name" required value={editedClientDetails.firstname}  onChange={(e) =>
                              setEditedClientDetails({
                                ...editedClientDetails,
                                firstname: e.target.value,
                              })
                            }/>
</div>
</div>
<div class="row">
<div class="col-md-12 col-sm-12 d-flex justify-content-center ">

<button type="button" className="btn btn-dark btn-md btn-rounded" style={{marginTop:"10px",marginBottom:"15px"}} onClick={handleEditClientDetails}>
                       Submit Changes
                      </button>
                      </div></div>

                      {responseMessage && (
                  <div className="response-message" style={{ color: "red" }}>
                    {responseMessage}
                  </div>
                )}
<div class="row">
<div class="col-sm-12 d-flex justify-content-center ">
<small class="d-block form-text mb-7">This will be how your name will be displayed
in the account section and in Previews</small></div></div>
<div class="row mt-3">
<div class="col-md-6 col-sm-12">
<label>Email Address *</label>
<input type="email" class="form-control" name="email" required  value={clientDetails.email || ""}/>
</div>
<div class="col-md-6 col-sm-12">
  <label>Mobile Number *</label>
  <input
    type="text"
    class="form-control"
    name="mobile"
    required
    value={mobilenumber}  
    onChange={handleMobileChange} 
  />
  
  <div style={{ color: 'red' }}>{errorMessage}</div>
  </div> 
  </div> 
  <div class="row mt-3">
<div class="col-sm-12 col-md-12  d-flex justify-content-center edit-number mt-3">
        {!mobileotpverify ?(
        <button type="button" className="btn btn-dark btn-md btn-rounded" onClick={sendOtptoMobile}>
                            Edit Mobile Number
                            </button>):(
                            <div className="edit-number-otp">
                            <input type="text" class="form-control" name="mobileotp" placeholder="otp" onChange={(e) =>
            setMobileotp({
              mobileotp: e.target.value,
            })
          } /> <span className="edit-number-otp-button d-flex justify-content-center">
          <button type="button" className="btn btn-dark btn-md btn-rounded" style={{marginRight:"10%"}} onClick={sendOtptoMobile}>
                            Resend OTP
                            </button>
          <button type="button" className="btn btn-dark btn-md btn-rounded" onClick={verifyOtptoMobile}>Verify</button></span>
                            </div>)
      }
   
   </div></div>




</form>
</div>
</div>    
</div>
</div>
</div>

</div>
</div>
</main>   
<Ecomfooter/>
 </div>
    )
}

export default ProfileChange;