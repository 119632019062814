import React, { useState, useEffect } from "react";
// import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import '../header/headshop.css';
import AesCryptUtil from './AesCryptUtil'; // Import AesCryptUtil implementation


// const promise = loadStripe("pk_live_51OChFxI0wQSu3P50fqcaBLffjOwFTqVdBIus13MxZgOredCuCXatXVsugcMUfO9kh4wqYMCNiPSm4cqhfpPm7BD800jc8ESOGf");
// Initialize Stripe Elements
function Stripe({orderData,clientSecret, isSucceeded, total}) {
  console.log('orderData',orderData)
  useEffect(() => {
    if (orderData) {
      const merchant_id = "2032711"; // Put your merchant id here
      const access_code = "AVCU30KC70BP50UCPB"; // Put access code here
      const enc_key = "BE46B77D1934D5E407375AB9CC5DAC6C"; // Put encryption key here
      const requestData = {
        currency: 'INR',
        cancel_url: `${window.location.origin}`,
        merchant_id: merchant_id,
        access_code: access_code,
        order_id: localStorage.getItem("selectedOrder"),
        amount: parseFloat(total),
        redirect_url: `${window.location.origin}/PaymentSuccess?orderData=${encodeURIComponent(JSON.stringify(orderData))}`, // Change this to your actual redirect URL
        language: 'EN'
        // Add any other required order data here
      };
console.log('requestData',requestData)
      const ccaRequest = Object.keys(requestData)
        .map((key) => `${key}=${requestData[key]}`)
        .join('&');

      const aesUtil = new AesCryptUtil(enc_key);
      const encRequest = aesUtil.encrypt(ccaRequest);

      // Form submission
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction';

      const encRequestInput = document.createElement('input');
      encRequestInput.type = 'hidden';
      encRequestInput.name = 'encRequest';
      encRequestInput.value = encRequest;

      const accessCodeInput = document.createElement('input');
      accessCodeInput.type = 'hidden';
      accessCodeInput.name = 'access_code';
      accessCodeInput.value = access_code;

      form.appendChild(encRequestInput);
      form.appendChild(accessCodeInput);

      document.body.appendChild(form);

      form.submit();

      return () => {
        document.body.removeChild(form);
      };
    } else {
      console.log("No order data provided.");
    }
  }, [orderData]);

  return <div>Redirecting to CCAvenue...</div>;
  // return (

  //   <>
  //   <div style={{display: 'flex', justifyContent: 'center',width:"100%", height: '100vh'}}>
  //   <div style={{width:"30%",  alignSelf: 'center'}}>
  //     {clientSecret && promise && (
  //       <Elements stripe={promise} options={{ clientSecret }}>
  //         <CheckoutForm orderData={orderData} isSucceeded ={isSucceeded} />
  //       </Elements>
  //     )}</div></div>
  //   </>
  // );
}

export default Stripe;