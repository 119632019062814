import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import '../../src/body/cart.css';
import '../header/headshop.css';
import '../../src/fonts/direction-icon.css';
import { getCartListAPI, getWishListAPI } from "../Redux/Api";
import { useSelector, useDispatch } from 'react-redux'
import { updateCartStateValue, updateWishListStateValue } from "../Redux/Redux";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import TopMenu from "../../src/topmenu/index";
import { ReactComponent as Logo } from "../../src/images/logo.svg";
import { jwtDecode } from "jwt-decode";
import { Modal, Button } from 'antd';
import   Twlogo  from "../../src/images/tw_logo.png"


const Ecomheader = () => {
  const token = localStorage.getItem('token');
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [renderComponent, setRenderComponent] = useState(false);
  const [isModalVisibleforNotification, setIsModalVisibleforNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [visibleNotifications, setVisibleNotifications] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [icons, setIcons] = useState([]);
  const closeAlert = () => {
    setShowAlert(false);
  };
  const togglePopupVisibilityforNotification = () => {
    setIsModalVisibleforNotification(true);
    setShowAlert(true);
};

  useEffect(() => {
    const token = localStorage.getItem('token'); // Assuming you store the token in localStorage

    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        // Token has expired
        setIsTokenExpired(true);
        localStorage.removeItem('token');
      } else {
        // Token is still valid
        setIsTokenExpired(false);
      }
    }
    const timer = setTimeout(() => {
      setRenderComponent(true);
    }, 50);
  }, []);
  
  const [data, setData] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("signin");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [mobile, setMobile] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [showOtpField, setShowOtpField] = useState(false);
  const [showOtpFieldforlogin, setShowOtpFieldforlogin] = useState(true);
  const [otp, setOtp] = useState("");
  const [otpsend, setOtepsend] = useState("");
  const [showRegisterButton, setShowRegisterButton] = useState(true);
  const [registrationMessage, setRegistrationMessage] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({});
  const [subcategoryDropdownStates, setSubcategoryDropdownStates] = useState({});
  const [subsubcategoryVisible, setSubsubcategoryVisible] = useState({});
  const [responseMessage, setResponseMessage] = useState('');
  const [otpResponseMessage, setOtpResponseMessage] = useState('');
  const [submiOtpResponse, setSubmiOtpResponse] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [searchList, setSearchList] = useState(null);
  const [isInputFocused, setInputFocused] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const wishListValue = useSelector((state) => state.wishlist.value);
  const cartValue = useSelector((state) => state.cart.value);
  const { width } = useWindowDimensions();
  const [searchTerm, setSearchTerm] = useState('');
  const [countrySelectPopup, setCountrySelectPopup] = useState('country-unselected');
  const [countryList, setCountryList] = useState([]);

  const isMobileDevice = width < 800;
  
  useEffect(() => {
    const fetchDataforNotification = async () => {
        try {
            const response = await fetch('https://api.starmallonline.com/api/v1/notification/get', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`, // Use the token for authorization
                },
                body: JSON.stringify({
                    clientid : localStorage.getItem("clientid")
                }),
            });
            const data = await response.json();
            setNotificationData(data);
            setVisibleNotifications(data.slice(0, 4));
        } catch (error) {
            console.error('Error fetching notification data:', error);
        }
    };

      fetchDataforNotification();
}, [isModalVisibleforNotification]);
  const toggleCategoryDropdown = (categoryId) => {
    setDropdownStates({
      ...dropdownStates,
      [categoryId]: !dropdownStates[categoryId] || false,
    });
  };
  const handleViewAll = () => {
    setVisibleNotifications(notificationData);
    navigate("/NotificationList")
};
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  // Function to toggle the subcategory dropdown
  const toggleSubcategoryDropdown = (subcategoryid) => {
    setSubcategoryDropdownStates({
      ...subcategoryDropdownStates,
      [subcategoryid]: !subcategoryDropdownStates[subcategoryid] || false,
    });
  };
  const toggleSubsubcategoryVisibility = (subcategoryId) => {
    setSubsubcategoryVisible((prevVisibility) => ({
      ...prevVisibility,
      [subcategoryId]: !prevVisibility[subcategoryId],
    }));
  };
  const handleOtpSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('otp', otp); // Append OTP value
      formData.append('mobile', mobile); // Append mobile value
      formData.append('token', token); // Append the token value

      const response = await axios.put("https://api.starmallonline.com/api/v1/otp/verify", formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to form data
        },
      });


      if (response.status === 200) {
        if (response.data.message === "success") {
          alert("Success: " + response.data.message);
          window.location.reload();
        } else {
          alert("Failed: " + response.data.message);
          setRegistrationMessage(response);
          // OTP verification successful
        }

        // You can perform further actions upon successful OTP verification here
      } else {
        console.error("OTP verification failed");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };
  const togglePopupVisibility = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const [mobileError, setMobileError] = useState("");
  const handleLogin = async () => {
    // Validate mobile number (must contain exactly 10 digits)
    if (!/^\d{10}$/.test(email)) {
      setMobileError("Please enter a valid 10-digit mobile number.");
      return;
    } else {
      setMobileError(""); // Clear the error message if mobile number is valid
    }
    try {
      const response = await fetch('https://api.starmallonline.com/api/v1/login/authenticateclient', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'mobile': email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        // After successful login, close the login popup
        setIsPopupVisible(false);
        setShowOtpField(true);
        window.location.reload();
        // After successful login, make the API call to get client details
        const getClientDetailsResponse = await fetch('https://api.starmallonline.com/api/v1/login/getclientdetails', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${data.token}`,
          },
        });

        if (getClientDetailsResponse.ok) {
          const clientData = await getClientDetailsResponse.json();
          // Now you can use clientData for further actions or state updates
        } else {
          console.error('Failed to get client details');
        }

        navigate('');
      } else {
        console.error('Client Login failed');
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  const handleRegister = async () => {
    if (mobile.length !== 10) {
      setMobileError("Mobile number must be 10 digits.");
      return;
    } else {
      setMobileError(""); // Clear the error message if the mobile number is valid
    }
    try {
      // Register the new client
      const registerResponse = await fetch('https://api.starmallonline.com/api/v1/login/registerclient', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, firstname, mobile }),
      });

      if (registerResponse.ok) {
        const responseData = await registerResponse.json();
        localStorage.setItem('token', responseData.token);
        // Toggle the visibility of the Register button
        setShowRegisterButton(false);
        // Now you can use the 'token' and 'message' as needed
        setShowOtpField(true);
        // If registration is successful, proceed to get client details
        const getClientDetailsResponse = await fetch('https://api.starmallonline.com/v1/login/getclientdetails', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (getClientDetailsResponse.ok) {
          setShowOtpField(true);
          const clientData = await getClientDetailsResponse.json();
          // Now you can use clientData for further actions or state updates
        } else {
          console.error('Failed to get client details');
        }
      } else {
        console.error('Client registration failed');
      }
    } catch (error) {
      console.error('Error registering client:', error);
    }
  };


  const onFinish = (values) => {
    if (activeTab === "signin") {
      handleLogin(values);
    } else if (activeTab === "register") {
      handleRegister(values);
    }
  };
  const headerCategoryList = async () => {
    try {
      const payload = {
        currency: localStorage.getItem('currencyName')
      };
      const response = await axios.put("https://api.starmallonline.com/api/v1/product/getcategoryAndsubcategory",payload, {

        headers: {
          // 'Authorization': 'Bearer ' +token,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate('/login');
        return; // Exit early to prevent further logic execution
      }
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    headerCategoryList();
  }, []);


  // Function to get the cart count from localStorage
  // const getCartCount = () => {
  //   const cartData = JSON.parse(localStorage.getItem('cart'));
  //   return cartData ? cartData.length : 0;
  // };

  const getWishListCount = async () => {
    //Fetching data from Server for Wishlist
    if (localStorage.getItem('token')) {
      let wishListData = await getWishListAPI();
      dispatch(updateWishListStateValue(wishListData));
    }
    //Else update it from Local Storage
    else {
      dispatch(updateWishListStateValue(JSON.parse(localStorage.getItem('wishlist')) || []))
    }
  }

  const getCartCount = async () => {
    //Fetching data from Server for Wishlist
    if (localStorage.getItem('token')) {
      let cartData = await getCartListAPI();
      dispatch(updateCartStateValue(cartData));
    }
    //Else update it from Local Storage
    else {
      dispatch(updateCartStateValue(JSON.parse(localStorage.getItem('cart')) || []))
    }
  }
  // State to hold the cart count
  // const [cartCount, setCartCount] = useState(getCartCount());
  useEffect(() => {
    // Update cart count when the component mounts and whenever cartData changes
    getCartCount();
    getWishListCount();

  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('https://api.starmallonline.com/api/v1/login/getclientdetails', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const clientData = await response.json();
          setUserDetails(clientData); // Store user details in state
        } else {
          console.error('Failed to get client details');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    // Call the function to fetch user details
    fetchUserDetails();
  }, []);

  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem('token');
    localStorage.clear();
    sessionStorage.clear();

    const currentURL = window.location.href;
    if (currentURL.includes('HomePage')) {
      window.location.reload(false)
    }
    else {
      navigate("/HomePage");
    }
  };
  const handleMobileChange = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) && newValue.length <= 10) {
      setMobile(newValue);
    }
  };
  const sendOtp = async () => {
    try {
      // Create a request payload with the mobile number
      const requestData = {
        mobile: mobile, // Use the state variable 'mobile' for the mobile number
      };

      // Make an HTTP POST request to the mobileotpclient API
      const response = await axios.put(
        'https://api.starmallonline.com/api/v1/client/mobileotpclient',
        requestData
      );

      if (response.status === 200) {
        // Successfully sent OTP
        setOtpResponseMessage('OTP sent successfully. Check your mobile for the OTP.');
        setShowOtpFieldforlogin(false);
      } else {
        // Handle other response statuses if needed
        setOtpResponseMessage('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      // Handle errors, e.g., network issues
      console.error('Error sending OTP:', error);
      setOtpResponseMessage('An error occurred while sending OTP. Please try again later.');
    }

  };
  const submitOtp = async () => {
    try {
      const response = await axios.put("https://api.starmallonline.com/api/v1/login/clientotp", {
        otp,
        mobile,
      });

      // Assuming your API returns a token on success
      if (response.data.message === "success" && response.data.token) {
        localStorage.setItem('token', response.data.token);
        setSubmiOtpResponse("Success: " + response.data.message);
        // Redirect or perform any actions after successful OTP verification
        navigate('/HomePage');
      } else {
        setSubmiOtpResponse("Failed: " + response.data.message + ' ' + "Resend the OTP");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  const sendOtptoMobile = async () => {
    try {
      const response = await fetch("https://api.starmallonline.com/api/v1/client/mobileotpclient", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile: mobile,

        }),
      });

      if (response.ok) {
      } else {
        console.error("Failed to send OTP");
        // Handle the error here
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      // Handle the error here
    }
  };
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await fetch('https://api.starmallonline.com/api/v1/logo/getall', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',

          },
        });
        if (response.ok) {
          const data = await response.json();
          setLogoUrl(data.logo); // Update the logo URL in the state
        } else {
          console.error('Failed to fetch logo');
        }
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchLogo();
  }, []);
  useEffect(() => {
    const fetchCountryrDetails = async () => {
      try {
        const response = await axios.put('https://api.starmallonline.com/api/v1/country/get', {});

        if (response.status === 200) {
          const data = response.data;
          // setCountryList(data);
          
          if(localStorage.getItem('currencyName')==null ||localStorage.getItem('currencyName')==null ){
            localStorage.setItem('selectedCountry', 'INDIA');
            localStorage.setItem('currencySymbol', '₹');
            localStorage.setItem('currencyName', 'INR');
            localStorage.setItem('mobileprefix', '+91');
            localStorage.setItem('mobilemaxlength', '10');
              }
        } else {
          console.error('Failed to get country details');
        }
      } catch (error) {
        console.error('Error fetching country details:', error);
      }
    };

    // Call the function to fetch user details
    fetchCountryrDetails();
  }, []);




  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        // Click occurred outside the input element
        setSearchList(null);
        // Perform your desired action here
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleInputFocus = () => {
    setInputFocused(true);
  };
  const handleToggleClick = async () => {
    // Access the body element and toggle the className
    document.body.classList.toggle("mmenu-active");
  
    try {
      // Fetch categories from the API
      const categoryResponse = await axios.put("https://api.starmallonline.com/api/v1/product/getcategoryAndsubcategory");
      if (categoryResponse.status === 401 || categoryResponse.status === 404) {
        return;
      }
  
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  
    try {
      // Fetch user details from the API
      const userResponse = await fetch('https://api.starmallonline.com/api/v1/login/getclientdetails', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (userResponse.ok) {
        const clientData = await userResponse.json();
        // Store user details in state
      } else {
        console.error('Failed to get client details');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };
  
  const handleInputChangeforSearchItem = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSubmitforSearchItem = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('search', searchTerm);
      formData.append('currency',localStorage.getItem('currencyName'))
      // Make the API call
      const response = await fetch(`https://api.starmallonline.com/api/v1/product/search?search=${searchTerm}&currency=${localStorage.getItem('currencyName')}`, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
        },
        // body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      navigate('/SearchItems',{state: { searchData : data}});
      // Handle the API response data here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error.message);
    }
  };
  const [selectedCountry, setSelectedCountry] = useState(null);
  useEffect(() => {
    // Load selected country from local storage on component mount
    const storedCountry = localStorage.getItem('selectedCountry');
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  }, []);
  const handleCountrySelect = (selectedCountry) => {
    console.log('s',selectedCountry);
    const selectedCountryData = countryList.find(countryData => countryData.country === selectedCountry);
    if (selectedCountryData) {
      const { symbol, currencycode, mobileprefix, mobilemaxlength } = selectedCountryData;
      const confirmChange = window.confirm(`Are you sure you want to change the country to ${selectedCountry}?`);
      if ( confirmChange ) {
        setSelectedCountry(selectedCountry);
        localStorage.setItem('selectedCountry', selectedCountry);
        localStorage.setItem('currencySymbol', symbol);
        localStorage.setItem('currencyName', currencycode);
        localStorage.setItem('mobileprefix', mobileprefix);
        localStorage.setItem('mobilemaxlength', mobilemaxlength);
      }
      window.location.href = '/HomePage';
    }
    else {
      console.error(`Data for ${selectedCountry} not found in the countryList`);
    }
  }
  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    handleCountrySelect(selectedValue);
  };
  useEffect(() => {
    const socialIcons = async () => {
try{
  const response = await fetch('https://api.starmallonline.com/api/v1/socialmedia/get',{
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if(response.ok) {
    const data = await response.json();
    setIcons(data);
  }else {
  }
} catch (error) {
  console.error('Error fetching icon:', error);
}
    };
    socialIcons();
  }, []);
  return (
    <div  >
      <header className={`header`}>
      <div className="header-top">
      <div className="container d-flex justify-content-between">
      
                <div className="hed-top-input d-flex  align-items-center">
                  <div className="social-media">
                 
                  {icons.map((icon) => (
            <a href={icon.description} target="_blank" key={icon.socialmediaid}>
                <i className={`r-icon ${icon.iconname}`}></i>
            </a>
        ))}
                 
                  </div> 
                  <div className="mall-details ml-2"> <Link to="/HomePage"> Home </Link>| <Link to="/AboutUs">About </Link> | Store Timings : 10:00 AM - 10:00 PM|</div>
                </div>
              <div className="flex mobile-cart-wishlist-riot">
              {/* <span>welcome {userDetails.firstname}</span> */}
              <div className=" mr-0  " >
                <div className="dropdown">
                      
                  {userDetails.firstname ? (
                    <div className=" riot-login">
                     {userDetails.firstname}(Profile)
                    
                  </div>
                    // <a href="#log"><i className="d-icon-user"></i></a>
                    // <i className="d-icon-user" onClick={togglePopupVisibility}></i>
                  ) : null}
                  <ul className="dropdown-box">
                    <li>
                      <Link to="/UserProfile">{userDetails.firstname}(Profile)</Link>
                    </li>
                    <li>
                      <Link to="/AccountOrders">ORDERS</Link>
                    </li>
                    <li>
                    <Link to="/UserProfile">ADDRESS</Link> 
                    </li>
                    
                    <li>
                      <Link to="/HomePage" onClick={handleLogout}>
                        LOGOUT
                      </Link>
                    </li>

                  </ul>
                </div>

              </div>
               
              {!(userDetails.firstname || userDetails.lastname) && (
                <Link to="/LoginPage" className="d-flex align-items-center">               
            <div className=" ">
            {/* <span>Sign In/Register</span>  */}
            Login / Signup
                  
                  </div>
                  </Link>
              )}
              {/* </a> */}
               
                <Link to="/Cart">
                <div className="cart-dount">
                 <i className="r-icon r-icon-cart " onClick={() => navigate('/Cart')}></i>
                 <div className="header-wishlist-cart-count">{cartValue.length}</div>
                </div>
                </Link>

              </div>
                
           
      </div>
      </div>
        <div className="header-middle">
          <div className="container">
            <div className="header-left">
            <a href="#" className="mobile-menu-toggle" onClick={handleToggleClick}>
<i className="d-icon-bars2"></i>
</a>
              <Link to="/HomePage">
                <Logo  alt="Logo" width="160"   className="top-logo " />
              </Link>
             
      
              <div className="header-search header-search-desktop hs-simple relative ml-2">
                <form action="#" className="input-wrapper" onSubmit={handleSubmitforSearchItem}>
                  <input type="text" className="form-control" name="search" autocomplete="off" placeholder="Search... "
                    value={searchTerm}  onChange={handleInputChangeforSearchItem}
                  />
                  <button className="btn btn-search" type="submit" title="submit-button ">
                    <i className="d-icon-search" ></i>
                  </button>
                </form>

              </div>
     


            </div>
            <div className="twlogo">
       <img src={Twlogo} alt="twlogo" width="160" />  
       </div>
            
         


          </div>
        </div>
        <div className="header-bottom"> 
        <div className="container">
        <nav>
        <TopMenu/>
      </nav>
      <ul style={{ display: 'flex', listStyle: 'none', padding: 0 }}>
  {data.map((category) => (
                  <Link to={`/MenuCategory/${category.categoryid}`} className="categoryfont">
    <li key={category.categoryid} style={{ margin: '0 10px' }}>{category.categoryname}</li></Link>
  ))}
</ul>


      </div>
      </div>
        {window.location.pathname === "/Cart"  || window.location.pathname === "/BuyCheckout" || window.location.pathname === "/Checkout"? (
      ''): (
        <div className="search-for-mobile">
        <div className="header-search hs-simple">
          <form action="#" className="input-wrapper" onSubmit={handleSubmitforSearchItem}>
                  <input type="text" className="form-control" name="search" autocomplete="off" placeholder="Search... "
                    value={searchTerm} onChange={handleInputChangeforSearchItem}
                  />
                  <button className="btn btn-search" type="submit" title="submit-button ">
                    <i className="d-icon-search" ></i>
                  </button>
                </form></div>
        </div>
      )}
         <div className={`header-bottom d-lg-show ${width > 800 ? "scale-[80%]" : ""}`}>
           
          
            
          
        </div>  
      </header>
 
    
     

      {/* <Category/> */}
      {/* <Ecomfooter/> */}
    </div>
    

  );
}

export default Ecomheader