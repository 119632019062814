import React, { useEffect, useState, Suspense } from "react";
import { Helmet } from "react-helmet";
import { fetchNotifications } from "../api";
import {  useNavigate } from "react-router-dom";
const Ecomfooter = React.lazy(() => import("../footer/footershop"));
const Ecomheader = React.lazy(() => import("../../src/header/headershop"));

const NotificationList = () => {
    const [notifications, setNotifications] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const loadNotifications = async () => {
            try {
                const data = await fetchNotifications();
                setNotifications(data);
            } catch (error) {
                setError(error.message);
                console.error('Error fetching notifications:', error);
            }
        };

        loadNotifications();
    }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

    if (error) {
        return <div>Error: {error}</div>;
    }const handleViewNotification = (link) => {
        let modifiedLink = link.replace('https://api.starmallonline.com/', '');
        if (modifiedLink.includes('ViewOrder')) {
            const orderId = parseInt(modifiedLink.replace('ViewOrder/', ''), 10);
            navigate('/ViewOrder/' + orderId);
        }
        if (modifiedLink.includes('AccountCoupons')) {
            const couponId = parseInt(modifiedLink.replace('AccountCoupons/', ''), 10);
            navigate('/AccountCoupons/' + couponId);
        }
    }
    
    return (
        <> 
        <Helmet>
        <title>Notifications</title>
    </Helmet>
        <Suspense fallback={<div className="container-fluied"><div className="row"><div className="loader"></div></div></div>}>
        <Ecomheader />
        <div className="container homebg notification d-flex justify-content-center pt-5">

                <div className="col-lg-6 col-md-6 col-sm-12 ">
       
            <h2 className="title title-center text-center">Notifications</h2>
            <ul className="notification-in">
                {notifications.map(notification => (
                    <li key={notification.notificationid} className="d-flex justify-content-between align-items-center">
                       <div className="notificetion-title">{notification.type}</div> 
                        <button onClick={() => handleViewNotification(notification.link)}  >View</button>
                    </li>
                ))}
            </ul>

            </div>
        </div>
         
        <Ecomfooter/>
            </Suspense>
        </>
    );
};

export default NotificationList;
