import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios"; 
const Ecomfooter = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [footerData, setFooterData] = useState({});
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [countryList, setCountryList] = useState([]);
  const [showTopButton, setShowTopButton] = useState(false);
  const [icons, setIcons] = useState([]);
  useEffect(() => {
    const updateYear = () => {
      const newYear = new Date().getFullYear();
      setCurrentYear(newYear)
    };
    updateYear();
    const intervalId = setInterval(updateYear,60000);
    return () => clearInterval(intervalId);
  },[]);
  useEffect(() => {
    // Define the PUT request data
    const putData = {
      currency:localStorage.getItem('currencyName')
      // Your PUT request data here
    };

    // Make the PUT request
    axios
      .put(
        "https://api.starmallonline.com/api/v1/product/getcategoryAndsubcategory",
        putData
      )
      .then((response) => {
        // Handle the response data here
        setCategoryData(response.data);
      })
      .catch((error) => {
        console.error("Error making PUT request:", error);
      });
  }, []);
  useEffect(() => {
    // Function to fetch client details to check authentication
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          "https://api.starmallonline.com/api/v1/login/getclientdetails",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.ok) {
          setIsAuthenticated(true); // User is authenticated
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);

  useEffect(() => {
    const fetchFooter = async () => {
      try {
        const response = await fetch('https://api.starmallonline.com/api/v1/logo/getall',{
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            
          },
        });
        if (response.ok) {
          const data = await response.json();
          setFooterData(data);
        } else {
          console.error('Failed to fetch logo');
        }
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchFooter();
  }, []); 
  useEffect(() => {
    const fetchCountryrDetails = async () => {
      try {
        const response = await axios.put('https://api.starmallonline.com/api/v1/country/get', {});

        if (response.status === 200) {
          const data = response.data;
          setCountryList(data);
        } else {
          console.error('Failed to get country details');
        }
      } catch (error) {
        console.error('Error fetching country details:', error);
      }
    };

    // Call the function to fetch user details
    fetchCountryrDetails();
  }, []);
  const [selectedCountry, setSelectedCountry] = useState(null);
  useEffect(() => {
    // Load selected country from local storage on component mount
    const storedCountry = localStorage.getItem('selectedCountry');
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  }, []);
  const handleCountrySelect = (selectedCountry) => {
    const selectedCountryData = countryList.find(countryData => countryData.country === selectedCountry);
    if (selectedCountryData) {
      const { symbol, currencycode, mobilemaxlength, mobileprefix } = selectedCountryData;
      const confirmChange = window.confirm(`Are you sure you want to change the country to ${selectedCountry}?`);
      if ( confirmChange ) {
        setSelectedCountry(selectedCountry);
        localStorage.setItem('selectedCountry', selectedCountry);
        localStorage.setItem('currencySymbol', symbol);
        localStorage.setItem('currencyName', currencycode);
        localStorage.setItem('mobilemaxlength', mobilemaxlength);
        localStorage.setItem('mobileprefix', mobileprefix);
      }
      window.location.href = '/HomePage';
    }
    else {
      console.error(`Data for ${selectedCountry} not found in the countryList`);
    }
  }
  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    handleCountrySelect(selectedValue);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 20) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  useEffect(() => {
    const socialIcons = async () => {
try{
  const response = await fetch('https://api.starmallonline.com/api/v1/socialmedia/get',{
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if(response.ok) {
    const data = await response.json();
    setIcons(data);
  }else {
  }
} catch (error) {
  console.error('Error fetching icon:', error);
}
    };
    socialIcons();
  }, []);
  return (

   
      <div className="footer">
          <div className="container">
            <div className="row">
          <div className="col-lg-3 col-md-6">
                 
                 <h4>Location</h4>
                 <ul >
                   <li>
                   No 79, Valluvar Kottam High Rd,

                   </li>
                   <li>
                   Ponnangipuram,

                   </li>
                   <li>
                   Nungambakkam, Chennai, ,

                   </li>
                   <li>
                   Tamil Nadu 600034.
                   </li>
                   
                 </ul>
                
             </div>


            <div className="col-lg-3 col-md-6">

              <h4 >Support</h4>
              <ul >
              <li>
              Call : +91 97910 79329

              </li>
              <li>
              Call : 044 48656949

              </li>
              <li>
              Email : info@starmallonline.com
              </li>
              {/* <li>
              <Link to="/ContactUs">Contact US</Link>
              </li> */}
              </ul>

            </div>

           
          <div className="col-lg-3 col-md-6">
               
               <h4  >Policies</h4>
               <ul >
              <li>
              <Link to="/Terms">Terms & Conditions</Link>
              </li>
              <li>
              <Link to="/PrivacyPolicy">Privacy Policy</Link>
              </li>
              <li>
              <Link to="/Terms" target="_blank">Return Policy</Link>
              </li>
              <li>
              <Link to="/Terms" target="_blank">Shipping Policy</Link>
              </li>
              <li>
              <Link to="/AboutUs" target="_blank">About Us</Link>
              </li>
              {/* <li>
              <Link to="/ContactUs">Contact US</Link>
              </li> */}
              </ul>
             
           </div>
           <div className="col-lg-3 col-md-6">
               
                <h4>FOLLOW US ON</h4>
                 
                <div className="social-links">
        {icons.map((icon) => (
            <a href={icon.description} target="_blank" key={icon.socialmediaid}>
                <i className={`r-icon ${icon.iconname}`}></i>
            </a>
        ))}
    </div>
            {/* {showTopButton && (
              <button className="myBtn" id="myBtn" onClick={scrollToTop} title="Go to Top"><span  className="r-icon r-icon-uparrow"></span></button>
            )} */}
            <ul >
               
              
               <li>
               <a href="mailto:info@starmallonline.com" target="blank"> info@starmallonline.com</a>
               </li>
               
               </ul>
            
            </div>

           
            </div>
            </div>
            <div className="container">
            <p className="copyright " id="year">
            {footerData.companyname}; {currentYear}. {footerData.copyrights}
            </p>

            </div>
            </div>
 
 
  );
};

export default Ecomfooter;
