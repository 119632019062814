import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import { Modal, Button } from 'antd';

const Shopeasyship = () => {
  const [commonShippingInfo, setCommonShippingInfo] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  
  useEffect(() => {
    // Define the API endpoint
    const apiUrl = "https://api.starmallonline.com/api/v1/common/get";
    
    // Make the GET request to fetch common shipping info
    axios.put(apiUrl)
      .then((response) => {
        if (response.status === 200) {
          // Assuming that the response.data is an array of shipping info objects
          setCommonShippingInfo(response.data);
        } else {
          console.error("Failed to fetch common shipping info");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []); // Empty dependency array ensures the effect runs only once
  const handleLinkClick = (url) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setShowAlert(true);
    } else {
      window.location.href = url;
    }
  };

  const closeAlert = () => {
    setShowAlert(false);
  };
  return (
    <section className="container  ">
       
        <div className="facility">
          {commonShippingInfo.map((info, index) => (
            <div className="facility-in">
            <Link to="#" onClick={() => handleLinkClick(info.url)}>
              <div className="facility-image">
              <img src={info.iconimage} alt={info.title} className="w-16 h-16 object-contain sm:w-36 sm:h-36"/>
              </div>
              <div className="facility-text">
              <h4>{info.title}</h4>
                  <p className="mobile-hide">{info.description}</p>
                 
              </div>
                </Link>
                </div>
          ))}
        </div>
        <Modal
        visible={showAlert}
        onCancel={closeAlert}
        footer={[
          <Button key="login" type="btn btn-dark btn-block btn-rounded" onClick={() => {
            closeAlert();
            window.location.href = '/LoginPage';
          }}>
            Login
          </Button>,
        ]}
      >
        <p>Please Login to Proceed.</p>
      </Modal>
    </section>
  );
};

export default Shopeasyship;
