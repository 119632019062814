import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Ecomheader from "../../src/header/headershop";
import Ecomfooter from "../footer/footershop";
import axios from "axios";
import { Form, notification } from "antd";
import { Helmet } from "react-helmet";
import {
  faEye,
  faEyeSlash,
  faUser,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useDispatch} from 'react-redux'
import { transferCart, transferWishList } from "../Redux/Redux";
import NavHead from "../navbarHead";
import MobileMenu from "../../src/navbarHead/mobileNav";
import FacebookLogin from 'facebook-login';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const PopupShow = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("signin");
  const [mobileError, setMobileError] = useState("");
  const [showOtpFieldforlogin, setShowOtpFieldforlogin] = useState(true);
  const [textsize, settextsize] = useState(true);
  const [showOtpField, setShowOtpField] = useState(false);
  const [otpResponseMessage, setOtpResponseMessage] = useState("");
  const [submiOtpResponse, setSubmiOtpResponse] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showRegisterButton, setShowRegisterButton] = useState(true);
  const [registrationMessage, setRegistrationMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [firstname, setFirstname] = useState("");
  const [otp, setOtp] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showRegisterPassword, setShowRegisterPassword] = useState(false);
  const [showRegisterConfirmPassword, setShowRegisterConfirmPassword] = useState(false);
  const [mobileprefixforChange, setMobilePrefix] = useState('');
  const [mobilemaxlengthforChange, setMobileMaxLength] = useState('');
  const dispatch = useDispatch()
  const [countryList, setCountryList] = useState([]);
  const [loginWithOTpshow, setLoginWithOTpshow] = useState(false);
  const [decodedCredential, setDecodedCredential] = useState(null);

  const mobilePrefix = localStorage.getItem('mobileprefix');
  const countrySelection = localStorage.getItem('selectedCountry');
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleRegisterPasswordVisibility = () => {
    setShowRegisterPassword(!showRegisterPassword);
  };
  const toggleRegisterConfirmPasswordVisibility = () => {
    setShowRegisterConfirmPassword(!showRegisterConfirmPassword);
  };
  const handleTabClick = (tabName) => {
    if (tabName === "signin") {
      settextsize(true);
    } else if (tabName === "register") {
      settextsize(false);
    }
    setActiveTab(tabName);
  };

  const onFinish = (values) => {
    if (activeTab === "signin") {
      handleLogin(values);
    } 
  };

  const handleMobileChangeLogin = (e) => {
    const newValue = e.target.value;
   const maxLength = selectedCountry ? mobilemaxlengthforChange : parseInt(localStorage.getItem('mobilemaxlength'));
  if (/^\d*$/.test(newValue) && newValue.length <= maxLength) {
    setEmail(newValue);
  }
};
  const handleLogin = async () => {
    // if (email.length !== (selectedCountry ? mobilemaxlengthforChange : parseInt(localStorage.getItem('mobilemaxlength')))) {
    //   setMobileError("Mobile number must be " + (selectedCountry ? mobilemaxlengthforChange : parseInt(localStorage.getItem('mobilemaxlength'))) + " digits.");
    //   return;
    // } else {
    //   setMobileError("");
    // }
    try {
      const response = await fetch(
        "https://api.starmallonline.com/api/v1/login/authenticateclient",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mobile: email, password }),
        }
      );

        const data = await response.json();
        if (data.token != null) {
          localStorage.setItem("token", data.token);
          notification.success({
            message: 'Welcome to Starmall Online you successfully logged in',
            duration: 3, 
          })
          
        // After successful login, make the API call to get client details
        const getClientDetailsResponse = await fetch(
          "https://api.starmallonline.com/api/v1/login/getclientdetails",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

          const clientData = await getClientDetailsResponse.json();
          // Now you can use clientData for further actions or state updates
          localStorage.setItem('clientid',clientData.id)
          dispatch(transferWishList())
          dispatch(transferCart())
          navigate(-1);
          // After successful login, close the login popup
          setShowOtpField(true);
        } else {
          setResponseMessage(data.message);
        }
      
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };


  const sendOtp = async () => {
    try {
      if (!mobile) {
        setOtpResponseMessage("Please provide email/mobile number");
        return;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{10}$/;

    // Check if the input is a valid email address or mobile number
    if (!emailRegex.test(mobile) && !mobileRegex.test(mobile)) {
      setOtpResponseMessage("Invalid email/mobile number");
      return;
    }
      // Create a request payload with the mobile number
      const requestData = {
        mobile: mobile, // Use the state variable 'mobile' for the mobile number
        otp: 'userLogin',
      };

      // Make an HTTP POST request to the mobileotpclient API
      const response = await axios.put(
        "https://api.starmallonline.com/api/v1/client/mobileotpclient",
        requestData
      );

      if (response.status === 200) {
        const smsdata = response.data;
        if(smsdata == "not user not registered"){
        // Successfully sent OTP
        setOtpResponseMessage(
          "not user not registered"
        );
        }else{
          setOtpResponseMessage(smsdata);
          setShowOtpFieldforlogin(false);
        }
      } else {
        // Handle other response statuses if needed
        setOtpResponseMessage("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      // Handle errors, e.g., network issues
      console.error("Error sending OTP:", error);
      setOtpResponseMessage(
        "An error occurred while sending OTP. Please try again later."
      );
    }
  };

  const submitOtp = async () => {
    try {
      const response = await axios.put(
        "https://api.starmallonline.com/api/v1/login/clientotp",
        {
          otp,
          mobile,
        }
      );

      // Assuming your API returns a token on success
      if (response.data.message === "success" && response.data.token) {
        localStorage.setItem("token", response.data.token);
        setSubmiOtpResponse("Success: " + response.data.message);
        // Redirect or perform any actions after successful OTP verification
        navigate("/HomePage");
      } else {
        setSubmiOtpResponse(
          "Failed: " + response.data.message + " " + "Resend the OTP"
        );
        setOtp("");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  useEffect(() => {
    const fetchCountryrDetails = async () => {
      try {
        const response = await axios.put('https://api.starmallonline.com/api/v1/country/get', {});

        if (response.status === 200) {
          const data = response.data;
          setCountryList(data);
        } else {
          console.error('Failed to get country details');
        }
      } catch (error) {
        console.error('Error fetching country details:', error);
      }
    };

    // Call the function to fetch user details
    fetchCountryrDetails();
  }, []);
  useEffect(() => {
      setSelectedCountry(selectedCountry);
  }, []);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const handleCountrySelect = (selectedCountry) => {
    const selectedCountryData = countryList.find(countryData => countryData.country === selectedCountry);
    if (selectedCountryData) {
      const { symbol, currencycode, mobilemaxlength, mobileprefix } = selectedCountryData;
        setSelectedCountry(selectedCountry);
        setMobilePrefix(mobileprefix);
      setMobileMaxLength(mobilemaxlength);
    }
    else {
      console.error(`Data for ${selectedCountry} not found in the countryList`);
    }
  }
  const handleloginWithOTpshow = () => {
    setLoginWithOTpshow(!loginWithOTpshow);
  }
  const handleForgotPassword = () => {
    navigate("/Reset")
  }
  const handleGoogleLoginSuccess = async (credentialResponse) => {
    const credentialResponseDecoded = jwtDecode(credentialResponse.credential);
    console.log(credentialResponseDecoded);
    setDecodedCredential(credentialResponseDecoded);
    if (credentialResponseDecoded) {
      try {
        const response = await axios.put('https://api.starmallonline.com/api/v1/login/googleauth', credentialResponseDecoded);
        if(response.status === 200){
        const body = response.data.body;
        if(body.register){
          // navigate("/RegisterAuth", { state: { requestData: body } });
        }
        else{
          
        if (body.token != null) {
          localStorage.setItem("token", body.token);
          notification.success({
            message: 'Welcome to Starmall Online you successfully logged in',
            duration: 3, 
          })
          
        // After successful login, make the API call to get client details
        const getClientDetailsResponse = await fetch(
          "https://api.starmallonline.com/api/v1/login/getclientdetails",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

          const clientData = await getClientDetailsResponse.json();
          // Now you can use clientData for further actions or state updates
          localStorage.setItem('clientid',clientData.id)
          dispatch(transferWishList())
          dispatch(transferCart())
          navigate(-1);
          // After successful login, close the login popup
          setShowOtpField(true);
        } else {
          setResponseMessage(body.message);
        }
        }
      }
        console.log('Response from logindecode API:', response.data);
        // Handle response as needed
      } catch (error) {
        console.error('Error calling logindecode API:', error);
        // Handle error
      }
    }
  };
  const handleFacebookLogin = (response) => {
    // Handle Facebook login response
    console.log(response);
  };
  return (
    <div>
      <div>
        <Helmet>
          <title>Login/Register</title>
        </Helmet>
      </div>
      <Ecomheader />
      <MobileMenu/>
      <div className=" ">
       <div class="container homebg pt-5">

            <div className="row  d-flex justify-content-center  align-self-center  registration-login">
            <div class="col-lg-5 col-md-5 col-sm-12 form-box-for-border">
            <div class="form-box-header">
            <h2>Login</h2>
            </div>
            


            <Form onFinish={onFinish}>
                <div className="form-group">
                  <div className="row">
                  {/* <div className="col-md-4 col-sm-4  mt-5"><select
      className="form-control"
      value={selectedCountry}
      onChange={(e) => handleCountrySelect(e.target.value)}
    >
    <option value="">{countrySelection}</option>
      {countryList.map((countryData) => (
        <option key={countryData.countryselectionid} value={countryData.country}>
          {countryData.country}
        </option>
      ))}
    </select></div> */}

                    <div className="col-md-12 col-sm-12  mt-5 input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text"> {selectedCountry ? mobileprefixforChange : mobilePrefix}</span>
                      </div>
                      <input
                        type="tel"
                        className="form-control"
                        id="register-phone"
                        name="register-phone"
                        placeholder="Your Phone Number *"
                        value={email}
                        onChange={handleMobileChangeLogin}
                        required
                      />
                      <div className="input-group-append row">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                      </div>
                    </div>


                  </div>
                  {mobileError && (
                    <div className="error-message">{mobileError}</div>
                  )}
                </div>

                <div className="form-group mb-3">
                  <div className="row">
                    <div className="col-sm-12 input-group mt-5">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="singin-password"
                        name="singin-password"
                        placeholder="Password *"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <div className="input-group-append row">
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="password-toggle-button"
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </button>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="row">
                {responseMessage && (
                  <div className="col-sm-12 response-message" style={{ color: "red" }}>
                    {responseMessage}
                  </div>
                )}
                </div>
               <div className="row">
                    <div className="col-sm-12"> <button
                  className="btn btn-dark btn-block btn-rounded"
                  type="submit"
                >
                  Login
                </button></div></div>
                <div></div>
              </Form>
              <div className="row">
              {/* <div className="col-md-6 form-choice text-center" onClick={handleForgotPassword}>
                  <Link  className="ls-m">Forgot Password</Link><br/>
                 
                </div> */}
              <div className="col-md-12 form-choice text-center" onClick={handleloginWithOTpshow}>
                  <Link  className="ls-m">Login With OTP</Link><br/>
                 
                </div>
                
              </div>
                {loginWithOTpshow && 
                <>
                <div className="form-group">
                  <div className="row">
                  <div className="col-sm-12 input-group mb-3"><input
                        className="form-control"
                        id="mobile-number"
                        name="mobile-number"
                        placeholder="Mobile Number *"
                        value={mobile}
   onChange={(e) => {
    const newValue = e.target.value;
    const maxLength = selectedCountry ? mobilemaxlengthforChange : parseInt(localStorage.getItem('mobilemaxlength'));
    
    if (/^\d*$/.test(newValue) && newValue.length <= maxLength) {
      const inputValue = newValue.replace(/\s/g, '');
      setMobile(inputValue);
    }
  }}
  
                        // onChange={(e) =>{
                        //   const inputValue = e.target.value.replace(/\s/g, '');
                        //    setMobile(inputValue)
                        //   }}
                        required
                      />
                      
                      <div className="input-group-append row">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <label className="otpnotifi">For Email otp Please check your spam also</label> */}
                </div>
                <div className="row">
                    <div className="col-sm-12"> 
                <button
                  className="btn btn-dark btn-block btn-rounded"
                  onClick={sendOtp}
                  style={{
                    display: showOtpFieldforlogin ? "block" : "none",
                  }}
                >
                  Login with OTP
                </button></div></div>
                <div className="row">
                    <div className="col-sm-12"> 
                <button
                  className="btn btn-dark btn-block btn-rounded"
                  onClick={sendOtp}
                  style={{
                    display: !showOtpFieldforlogin ? "block" : "none",
                  }}
                >
                  Resend OTP
                </button></div></div>
                 <div className="row">
                    <div className="col-sm-12"> 

                {otpResponseMessage && (
                  <div className="response-message">{otpResponseMessage}</div>
                )}
                 <div
                style={{
                  display: !showOtpFieldforlogin ? "block" : "none",
                }}
              >
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="otp"
                    name="otp"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
                <button
                  className="btn btn-dark btn-block btn-rounded"
                  onClick={submitOtp}
                >
                  Submit OTP
                </button>
                {submiOtpResponse && (
                  <div className="response-message">{submiOtpResponse}</div>
                )}
                <div>
                
              </div>
              </div>
              </div></div>
              </>
}
              <div className="row">
                    <div className="col-sm-12"> 
              <Link to="/RegisterPopup" className="btn btn-light  btn-block btn-rounded" >Create an Account</Link>
              </div>
              </div>
              <div className="row">
             <div className="col-sm-6">
            
      </div>
             {/* <div className="col-sm-6">
      <FacebookLogin
      appId="YOUR_FACEBOOK_APP_ID"
      autoLoad={false}
      fields="name,email,picture"
      callback={handleFacebookLogin}
      render={(renderProps) => (
        <button onClick={renderProps.onClick}>Login with Facebook</button>
      )}
    />
    </div> */}
            </div> 
            </div> 
            </div>
            </div>
      <Ecomfooter />
    </div>
    </div>
  );
};
export default PopupShow;
