import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../../src/dashboard/style.css';
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";
import MobileMenu from "../../src/navbarHead/mobileNav";
import { Modal, Form, Input, Button } from "antd";

const UserProfile = () => {  
    const token = localStorage.getItem("token");
    const [form] = Form.useForm();
    const [clientDetails, setClientDetails] = useState({});
    const [billingData, setBillingData] = useState({});
    const [shippingAddress, setShippingAddress] = useState([]);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [data, setData] = useState({});
    const [dashBoardData, setDashBoardData] = useState([]);
    useEffect(() => {
      const fetchDashboardDetails = async () => {
        try {
          const response = await fetch("https://api.starmallonline.com/api/v1/dashboard/Buyerdashboard ", {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (response.ok) {
            const data = await response.json();
            setDashBoardData(data);
          } else {
            console.error("Failed to get client details");
          }
        } catch (error) {
          console.error("Error fetching client details:", error);
        }
      };
  
      fetchDashboardDetails();
    }, [token]);
     useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch("https://api.starmallonline.com/api/v1/login/getclientdetails", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setClientDetails(data);
        } else {
          console.error("Failed to get client details");
        }
      } catch (error) {
        console.error("Error fetching client details:", error);
      }
    };

    fetchClientDetails();
  }, [token]);
  const fetchBillingAddress = async () => {
    try {
      // Define the payload with the client ID
      const payload = {
        clientid: localStorage.getItem("clientid"),
      };

      // Make the API request to get billing address with the payload
      const response = await fetch(
        "https://api.starmallonline.com/api/v1/client/getbillingaddress",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        // Handle the received billing address data as needed
        setBillingData(data);
        
      } else {
        console.error("Failed to get billing address");
      }
    } catch (error) {
      console.error("Error fetching billing address:", error);
    }
  };
  const fetchAddressShipping = async (id) => {
    try {
      // Define the payload with the client ID
      const payload = {
        clientid: localStorage.getItem("clientid"),
      };

      // Make the API request to get address billing with the payload
      const response = await fetch(
        "https://api.starmallonline.com/api/v1/client/getaddress",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        // Set the address billing data in state
        setShippingAddress(data);
      } else {
        console.error("Failed to get address billing");
      }
    } catch (error) {
      console.error("Error fetching address billing:", error);
    }
  };
  useEffect(()=> {
    fetchBillingAddress();
    fetchAddressShipping();
  }, [])
    // Function to close the edit popup
    const closeEditPopup = () => {
        setIsEditPopupOpen(false);
      };
      const onFinish = async (values) => {
    
        try {
          // TODO: Replace with actual API call
          const response = await fetch(
            "https://api.starmallonline.com/api/v1/client/addaddress",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify({
                ...values,
              }),
            }
          );
    
          if (response.status === 401 || response.status === 404) {
            return;
          }
    
          if (response.ok) {
            const responseData = await response.json();
            setData(responseData.data);
            closeEditPopup(); // Close the popup after successful submission
            window.location.reload();
          } else {
          }
        } catch (error) {
        }
      };
    const openEditPopup = (address) => {
        form.setFieldsValue({
            clientid: address.clientid,
            region: address.region,
            clientaddressid: address.clientaddressid,
            name: address.name,
            companyname: address.companyname,
            country: address.country,
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            state: address.state,
            pincode: address.pincode,
            phonenumber: address.phonenumber,
            email: address.email
        });
        setIsEditPopupOpen(true);
    };
    
    return (
        <div>
        <Ecomheader/>
        <MobileMenu/>
        <section className="container homebg pt-8">

            <div className="row align-item-start user-dashboard-main">
                <div className="col-md-3  col-sm-12 justify-content-center ">
                   <div className="d-flex justify-content-center"><div className="user-profile-image "></div></div> 
                    <div className="profile-data">
                        <h5>NAME</h5>
                       <p>{clientDetails.firstname}</p>
                        <h5>EMAIL</h5>
                        <p>{clientDetails.email}</p>
                        <h5>MOBILE NUMBER</h5> 
                        <p>{clientDetails.mobile}</p>
                    </div>
                        
                        <Link to="/ProfileChange">
                        <div className="mb-5">
                       <button type="button" className="common-btn">Edit</button>
                       </div></Link>
                       <Link to="/AccountDetails">
                    <div className=" mb-5">
                        <button type="button" className="common-btn">Change password</button>
                    </div>
                        </Link>
                </div>

                <div className="col-md-9">
                  <div className="container-fluid">
                    
                    {/* <div className="row">
                        <div className="col-md-3 col-sm-6 text-center">
                        <div class="user-dashboard p-4 mx-auto"> 
                        <Link to="/AccountOrders">
                            <div class="container">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="icon-holder">
                                        <div class="icon"> 
                                            <i class="icofont-cart-alt"></i>
                                        </div> 
                                    </div> 
                                    <div class="mx-2"></div> 
                                    <div class="userdashboard-value text-center mr-3">
                                        {dashBoardData && dashBoardData.totalOrder} 
                                    </div>
                                </div>
                               <span>TOTAL ORDERS</span>
                            </div>
                            </Link>
                        </div> 
                        </div>
                        <div className="col-md-3  col-sm-6 text-center">
                        <div class="user-dashboard p-4 mx-auto"> 
                            <div class="container">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="icon-holder">
                                        <div class="icon"> 
                                            <i class="icofont-check-circled"></i>
                                        </div> 
                                    </div>
                                    <div class="mx-2"></div>
                                    <div class="userdashboard-value text-center mr-3">
                                        {dashBoardData && dashBoardData.newOrder} 
                                    </div>
                                </div>
                                <span >NEW ORDER</span>
                            </div>
                        </div> 
                        </div>
                        <div className="col-md-3  col-sm-6 text-center">
                        <div class="user-dashboard p-4 mx-auto">
                            <div class="container">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="icon-holder">
                                        <div class="icon"> 
                                            <i class="icofont-close-circled"></i>
                                        </div> 
                                    </div>
                                    <div class="mx-2"></div>
                                    <div class="userdashboard-value text-center mr-3">
                                        {dashBoardData && dashBoardData.cancelledOrder} 
                                    </div>
                                </div>
                                <span style={{whiteSpace: "nowrap", overflow: "hidden",textOverflow: "ellipsis"}}>CANCELED ORDER</span>
                            </div>
                        </div> 
                        </div>
                        <div className="col-md-3  col-sm-6 text-center">
                          <Link to="/WishList">
                        <div class="user-dashboard p-4 mx-auto"> 
                            <div class="container">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="icon-holder">
                                        <div class="icon"> 
                                            <i class="icofont-heart"></i>
                                        </div>  
                                    </div>
                                    <div class="mx-2"></div>
                                    <div class="userdashboard-value text-center mr-3">
                                         {dashBoardData && dashBoardData.wishcount} 
                                    </div>
                                </div>
                                <span >WISHLIST</span>
                            </div>
                        </div> 
                        </Link>
                        </div>
                    </div> */}
                    {/* <div className="row">
                        <div className="col-md-3  col-sm-6 text-center">
                        <div class="user-dashboard p-4 mx-auto"> 
                            <div class="container">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="icon-holder">
                                        <div class="icon"> 
                                            <i class="icofont-database"></i>
                                        </div>  
                                    </div>
                                    <div class="mx-2"></div>
                                    <div class="userdashboard-value text-center mr-3">
                                         {dashBoardData && dashBoardData.Coins} 
                                    </div>
                                </div>
                                <span >COINS</span>
                            </div>
                        </div> 
                        </div>
                        <div className="col-md-3  col-sm-6 text-center">
                          <Link to="/AccountCoupons">
                        <div class="user-dashboard p-4 mx-auto"> 
                                <div class="container">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="icon-holder">
                                            <div class="icon"> 
                                                <i class="icofont-gift"></i>
                                            </div> 
                                        </div>
                                        <div class="mx-2"></div> 
                                        <div class="userdashboard-value text-center mr-3">
                                            {dashBoardData && dashBoardData.CouponValue} 
                                        </div>
                                    </div>
                                    <span >COUPON</span>
                                </div>
                            </div> 
                            </Link>
                        </div>
                        <div className="col-md-3  col-sm-6 text-center">
                          <Link to="">
                        <div class="user-dashboard p-4 mx-auto">
                                <div class="container">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="icon-holder">
                                            <div class="icon"> 
                                                <i class="icofont-building"></i>
                                            </div>    
                                        </div>
                                        <div class="mx-2"></div>
                                        <div class="userdashboard-value text-center mr-3">
                                             {dashBoardData && dashBoardData.followCount} 
                                        </div>
                                    </div>
                                    <span >FOLLOWING SELLERS</span>
                                </div>
                            </div> 
                            </Link>
                        </div>
                        <div className="col-md-3  col-sm-6 text-center">
                          <Link to="/NotificationList">
                        <div class="user-dashboard p-4 mx-auto">
                                <div class="container">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="icon-holder">
                                            <div class="icon"> 
                                                <i class="r-icon r-icon-alert"></i>
                                            </div>    
                                        </div>
                                        <div class="mx-2"></div>
                                        <div class="userdashboard-value text-center mr-3">
                                             {dashBoardData && dashBoardData.followCount} 
                                        </div>
                                    </div>
                                    <span >Notifications</span>
                                </div>
                            </div> 
                            </Link>
                        </div>
                    </div> */}
                    </div>
                    <div className="row common-address">
                        <div className="col-md-12 col-sm-12">
                            <h5>BILLING ADDRESS</h5>
                            <div className="adress-container">
                                <h2>{billingData.name}</h2>
                                <p>{billingData.address1},{billingData.address2},{billingData.city},{billingData.state}-{billingData.pincode} 
                                    <Link to="/Billing" className="less">EDIT</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row common-address">
                      <div className="col-md-12 col-sm-12 d-flex justify-content-between mt-5">
                      <h5>SHIPPING ADDRESS</h5>
                      <Link
                            to="/ShippingForm"
                            className="btn btn-link btn-secondary btn-underline add-shipping-addres" style={{display:"flex",alignItems:"center",justifyContent:"center"}}
                          >
                            ADD<i className="far fa-edit"></i>
                          </Link>
                      </div>
                    </div>
                    <div className="row common-address">
                    
                        {shippingAddress.map((address) => (
                        <div className="col-md-6  col-sm-12" key={address.clientaddressid}>
                            
                                
                                <div className="adress-container">
                                    <h2>{address.name}</h2>
                                    <p>{address.address1},{address.address2},{address.city}-{address.pincode}
                                    <a className="less" onClick={() => openEditPopup(address)}>EDIT</a>

                                    </p>
                                </div>
                             
                        </div>

                        ))}
                    </div>
                    
                </div>
            </div>
        </section>
        {isEditPopupOpen && (
        <Modal
          title="Edit Shipping Address"
          visible={isEditPopupOpen}
          onCancel={closeEditPopup}
          footer={[
            <Button key="cancel" onClick={closeEditPopup}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              form="edit-form"
            >
              Save
            </Button>,
          ]}
        >
          <Form form={form} id="edit-form" onFinish={onFinish}>
          <Form.Item style={{display:"none"}} label="clientid" name="clientid">
              <Input type="text" />
            </Form.Item>
          <Form.Item style={{display:"none"}} label="region" name="region">
              <Input type="text" />
            </Form.Item>
          <Form.Item style={{display:"none"}} label="clientaddressid" name="clientaddressid">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Name" name="name">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Company Name" name="companyname">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Country" name="country">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Address 1" name="address1">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Address 2" name="address2">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="City" name="city">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="State" name="state">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Pincode" name="pincode">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Phone Number" name="phonenumber">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input type="text" />
            </Form.Item>
          </Form>
        </Modal>
      )}
<Ecomfooter/>
        </div>
    );
};

export default UserProfile;
