import React, { useEffect, useState, Suspense } from "react";
import { Helmet } from "react-helmet";
import { fetchCategories, fetchClientDetails } from "../api";

// Lazy-loaded components
const Ecomfooter = React.lazy(() => import("../footer/footershop"));
const Ecomheader = React.lazy(() => import("../../src/header/headershop"));
const Shopeasyhome = React.lazy(() => import("../../src/banner/insex"));
const Shopeasyship = React.lazy(() => import("../../src/facillity/index"));
const Shopeasyourcato = React.lazy(() => import("../../src/homecategory/index"));
const Shopeasyourbest = React.lazy(() => import("../../src/bestSellers/index"));
const Shopeasyouroffer = React.lazy(() => import("../../src/offer/index"));
const FeaturedProduct = React.lazy(() => import("../../src/featurdproduct/index"));
const NavHead = React.lazy(() => import("../navbarHead"));
const MobileMenu = React.lazy(() => import("../../src/navbarHead/mobileNav"));
const Theme  = React.lazy(() => import("../../src/theme/index"));

const HomePage = () => {
    const [categories, setCategories] = useState([]);
    const [categories1, setCategories1] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isShopeasyhomeVisible, setIsShopeasyhomeVisible] = useState(true);
    const [isThemeVisible, setIsThemeVisible] = useState(true);
    const toggleShopeasyhomeVisibility = () => {
      setIsShopeasyhomeVisible(!isShopeasyhomeVisible);
    };
    const toggleThemeVisibility = () => {
      setIsThemeVisible(!isThemeVisible);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
            getClientDetails(token);
        }
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const categoriesResponse = await fetchCategories();
            setCategories(categoriesResponse.slice(0, 8));
            setCategories1(categoriesResponse.slice(8, 16));
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const getClientDetails = async (token) => {
        try {
            const clientData = await fetchClientDetails(token);
            localStorage.setItem('clientid', clientData.id);
        } catch (error) {
            console.error("Error fetching client details:", error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Starmall</title> 
            </Helmet>
            <Suspense fallback={<div className="container-fluied"><div className="row"><div className="loader"></div></div></div>}>
                <Ecomheader />
                <MobileMenu />
                <div className="row gutter-no">
                    {/* <aside class="col-lg-3 col-xxl-sidebar page-sidebar sidebar sidebar-fixed sticky-sidebar-wrapper">
                        <NavHead/>
                    </aside> */}
                    <Shopeasyhome />
                    <Shopeasyship />
                    <Shopeasyhome section="2" />
                    <Shopeasyourcato categories={categories} showViewAll />
                    <Shopeasyhome section={3} />
                    
                    <Shopeasyhome section={4} />
                    <FeaturedProduct />
                    <Shopeasyhome section={5} />
                     
                    <Ecomfooter />
                </div> 
                 {isThemeVisible && (
        <Theme 
          onCloseShopeasyhome={toggleShopeasyhomeVisibility} 
          onCloseTheme={toggleThemeVisibility} 
          isShopeasyhomeVisible={isShopeasyhomeVisible} 
        />
      )}
            </Suspense>
        </>
    );
}

export default HomePage;
