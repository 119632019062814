import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Ecomheader from "../../src/header/headershop";
import Ecomfooter from "../footer/footershop";
import OnlyBilling from "../billing/OnlyBilling";
import { Helmet } from "react-helmet";
import _ from "lodash"
import Stripe from './../payment/Stripe'
import { Modal, Button,notification  } from 'antd';
import BillingAddressModal from "../../src/billing/editBillingModel";
import ShipppingForm from "../../src/shipping/index";

const BuyCheckout = () => {const navigate = useNavigate();
  const location = useLocation();
  const [navigationState, setNavigationState] = useState(location.state && location.state.productDetails ? location.state : { productDetails: {"previewimg":""} });
  useEffect(() => {
    // Update navigationState only if location.state is defined
    if (location.state) {
      setNavigationState(location.state);
      // Set navigationState in localStorage
      localStorage.setItem("navigateState", JSON.stringify(location.state));
    }
  }, [location.state]);
  
  
    console.log('navigationState',navigationState)
  const [billingaddress, setBillingaddress] = useState("");
  const [showBillingAddress, setshowBillingAddress] = useState(false); 
  const [sameAsBilling, setSameAsBilling] = useState(false); 
  const [addressBilling, setAddressBilling] = useState("");
  const [loading, setLoading] = useState(true);
  const [fname, setfName] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [email, setEmail] = useState("");
  const [pincodeCheck, setPincodeCheck] = useState({});
  const [data, setData] = useState([]);
  const [coupons1, setCoupons1] = useState([]);
  const [modalVisibleCoupon, setModalVisibleCoupon] = useState(false);
  const [showEditBillingAddress, setShowEditBillingAddress] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [enableOnlinePayment, setEnableOnlinePayment] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [isSucceeded, setIsSucceeded] = useState(false);
  const [couponcode, setCouponCode] = useState("");
  const [couponResponse, setCouponResponse] = useState("");
  const [couponDetail, setCouponDetail] = useState({});
  const [couponInputDisabled, setCouponInputDisabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ordertotalfinal, setOrdertotalfinal] = useState(0);
  const [billingAddress, setBillingAddress] = useState(null);
  useEffect(() => {
    fetchBillingAddress();
  }, []);
    const fetchBillingAddress = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          "https://api.starmallonline.com/api/v1/client/getbillingaddress",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setBillingaddress(data);
          setfName(billingaddress.name != null ? billingaddress.name : '');
        } else {
          console.error("Failed to get billing address");
        }
      } catch (error) {
        console.error("Error fetching billing address:", error);
      } finally{
        setLoading(false);
      }
    };


  useEffect(() => {
    // Function to fetch client details
    const fetchClientDetails = async () => {
      try {
        // Make the API request to get client details
        const response = await fetch(
          "https://api.starmallonline.com/api/v1/login/getclientdetails",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        // If the response is successful, set client details and call fetchAddressBilling
        if (response.ok) {
          const data = await response.json();
          // Store the clientid in localStorage
          localStorage.setItem("clientid", data.id);
          // Call the fetchAddressBilling function with the client's ID
          fetchAddressBilling(data.id);
        } else {
          console.error("Failed to get client details");
        }
      } catch (error) {
        console.error("Error fetching client details:", error);
      }
    };

    // Function to fetch address billing
    const fetchAddressBilling = async (id) => {
      try {
        // Define the payload with the client ID
        const payload = {
          clientid: id,
        };

        // Make the API request to get address billing with the payload
        const response = await fetch(
          "https://api.starmallonline.com/api/v1/client/getaddress",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(payload),
          }
        );

        if (response.ok) {
          const data = await response.json();
          // Set the address billing data in state
          setAddressBilling(data);
        } else {
          console.error("Failed to get address billing");
        }
      } catch (error) {
        console.error("Error fetching address billing:", error);
      }
    };

    // Call the fetchClientDetails function when the component mounts
    fetchClientDetails();
  }, []);

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);

  const handleAddressSelection = (index) => {
    if (selectedAddressIndex === index) {
      // If the same row is clicked again, deselect it
      setSelectedAddressIndex(null);
    } else {
      setSelectedAddressIndex(index);
    }
  };
  // Calculate the subtotal
  const subtotal = parseFloat(navigationState.quantity) * parseFloat(navigationState.productDetails.sellingprice);
  

  const onFinish = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    try {
      setLoading(true);
      // Check if clientDetails has the clientid property
      // Retrieve the clientid from localStorage
      const clientid = localStorage.getItem("clientid");

      // Check if clientid is present in localStorage
      if (!clientid) {
        console.error("clientid is missing in localStorage");
        return;
      }

      // Define the request body
      const requestBody = {
        clientid,
        name: fname,
        companyname,
        country,
        region,
        address1,
        address2,
        city,
        state,
        pincode,
        phonenumber,
        email,
        // clientbillingaddressid
      };

      // Check if billingaddress is null
      if (addressBilling === "") {
        // If it's null, don't include clientbillingaddressid in the request body
        // since it should be generated by the server
      } else {
        // If it's not null, include clientbillingaddressid in the request body
        requestBody.clientbillingaddressid =
          addressBilling.clientbillingaddressid;
      }

      // Make the API request to edit billing address
      const response = await fetch(
        "https://api.starmallonline.com/api/v1/client/editbillingaddress",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      // Handle the API response as needed
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate('/login');
        return;
      }

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        if (response.data && response.data.length > 0) {
          setData(response.data);
        } else {
          setData([]);
        }
        setLoading(false);
        if (data.token) {
          localStorage.setItem("token", token);
        }

        setShowEditBillingAddress(false);
      } else {
        console.error("POST request failed");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setLoading(false);
    }
  };
  const billingaddressValues = Object.values(billingaddress).filter(
    (value) => value !== null && value !== ""
  );
  // const billingaddressString = billingaddressValues.join(",");
  const billingaddressString = `${billingaddress.name}, ${billingaddress.address1}${billingaddress.address2 ? `, ${billingaddress.address2}` : ''}, ${billingaddress.city}, ${billingaddress.state}, ${billingaddress.pincode}`


  const handleOnlineOrder = () =>{

  }

  const fetchPaymentData = async (amount, currency , paymentMethod ) => {
    try {
      let adjustedAmount = parseFloat(amount);
      if (couponDetail && couponDetail.couponamount !== null) {
          adjustedAmount -= parseFloat(couponDetail.couponamount);
          if (adjustedAmount < 0) {
              adjustedAmount = 0; // Ensure the adjusted amount doesn't go negative
          }
      }
      const response = await fetch( 
        "https://api.starmallonline.com/api/v1/payment/payment",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "amount": amount,
            "currency": currency,
            "paymentmethod": paymentMethod
          })
        }
      );

      if (response.ok) {
        const data = await response.text();
        setClientSecret(data)
      } else {
        alert(JSON.stringify(response))
      }
    } catch (error) {
      console.error("Error in payment:", error);
    }
  };
  
  const currencyType = localStorage.getItem('currencyName')!=null ? localStorage.getItem('currencyName') :"";
        
  
const [orderDAta1,setOrderData1] = useState({});
  // Create a FormData object and append the productIds
  const formData = new FormData();
  formData.append('quantity', [navigationState.quantity]);
  const productIdsArray = [navigationState.productDetails.productid]; 
  formData.append('productIds', productIdsArray);
  const onFinished = async () => {
      try { 
      const response = await fetch( `https://api.starmallonline.com/api/v1/product/checkforproductwithquantity`,{
        method: "PUT",
        body: formData,
      });
      const qtyCheck = await response.json();

      if (qtyCheck.organiserId === "true"){
        const token = localStorage.getItem("token");
    
        try {
          setLoading(true);
          const onlinePaymentSelected = document.getElementById("flat_rate").checked;
          const cashOnDeliverySelected = document.getElementById("local_pickup").checked;
          if (!onlinePaymentSelected && !cashOnDeliverySelected) {
            alert("Please select a payment method.");
            setLoading(false);
            return;
          }
          // Find the selected shipping address
          let selectedAddress = addressBilling.find((address, index) => {
            const checkbox = document.getElementById(`checkbox-${index}`);
            return checkbox && checkbox.checked;
          });
          const selectedAddresses = addressBilling.filter((address, index) => {
            const checkbox = document.getElementById(`checkbox-${index}`);
            return checkbox && checkbox.checked;
        });

        if (sameAsBilling) {
          selectedAddress = billingaddress;
        } else {
          // Find the selected shipping address
          selectedAddress = addressBilling.find((address, index) => {
            const checkbox = document.getElementById(`radio-${index}`);
            return checkbox && checkbox.checked;
          });
        
          if (!selectedAddress) {
            alert("Please select one shipping address.");
            setLoading(false);
            return;
          }
        }
          
    
          if (!billingaddress || Object.keys(billingaddress).length === 0 || !selectedAddress) {
            setLoading(false)
            alert("Billing and Shipping addresses are required.");
            return;
          }
    
          // Define the request body
          const orderData = {
            clientid: localStorage.getItem("clientid"),
            products: [{
                productid: navigationState.productDetails.productid,
                productcount: navigationState.quantity,
                productprice: navigationState.productDetails.sellingprice,
                productsubtotal: parseFloat(navigationState.productDetails.sellingprice * navigationState.quantity).toFixed(2),
              }],
            total: pincodeCheck && pincodeCheck.shippingrate && pincodeCheck.cartvalue && parseFloat(pincodeCheck.cartvalue) > parseFloat(_.toString(subtotal.toFixed(2)))? parseFloat((couponDetail.couponamount !=null && couponDetail.couponamount ?_.toString(subtotal.toFixed(2) - couponDetail.couponamount) : _.toString(subtotal.toFixed(2)))) + parseFloat(pincodeCheck.shippingrate):(couponDetail.couponamount !=null && couponDetail.couponamount ?_.toString(subtotal.toFixed(2) - couponDetail.couponamount) : _.toString(subtotal.toFixed(2))),
            coupons: "", // You can set the coupons value as needed
            paymentmethod: enableOnlinePayment ? "online payment":"cod", // Set the payment mode
            paymentstatus: isSucceeded ? "paid" : 'pending', // Set the payment status
            orderStatus: "Placed", // Set the order status
            billingaddress: billingaddressString,
            shippingaddress: selectedAddress == null ? null : selectedAddress,
            notes: "", // Add any additional notes if needed
            subtotal: _.toString(subtotal.toFixed(2)),
            couponid: couponDetail.couponid,
            cuponvalue: couponDetail.couponamount,
            deliverycharge: pincodeCheck.shippingrate,
            currencytype: localStorage.getItem("currencyName"),
            currency: localStorage.getItem("currencySymbol"),
          };
          setOrderData1(orderData)
          setOrdertotalfinal(orderData.total);
          // Make the API request to add the order
          const response = await fetch(
            "https://api.starmallonline.com/api/v1/order/add",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify(orderData),
            }
          );
    
          // Handle the API response as needed
          if (response.status === 401 || response.status === 404) {
            // Redirect to the login page for 401 or 404 errors
            navigate('/login');
            return;
          }
    
          if (response.ok) {
    
    
            localStorage.setItem("orderDetails", JSON.stringify(orderData));
            localStorage.removeItem("cart");
            localStorage.removeItem("productData");
            const data = await response.json();
            const token = data.token;
    
            if(data.description.includes('Error')){
              alert(`Order Not created - Contact IT Support`)
              return;
            }
            // Store the organiserId in local storage
            if (data.organiserId) {
              localStorage.setItem("organiserId", data.organiserId);
              localStorage.setItem("selectedOrder", data.organiserId);
            }
            if(enableOnlinePayment){
    
              if(enableOnlinePayment && !clientSecret){
                await fetchPaymentData(
                  pincodeCheck && pincodeCheck.shippingrate && pincodeCheck.cartvalue && parseFloat(pincodeCheck.cartvalue) > parseFloat(_.toString(subtotal.toFixed(2)))? parseFloat((couponDetail.couponamount !=null && couponDetail.couponamount ?_.toString(subtotal.toFixed(2) - couponDetail.couponamount) : _.toString(subtotal.toFixed(2)))) + parseFloat(pincodeCheck.shippingrate):(couponDetail.couponamount !=null && couponDetail.couponamount ?_.toString(subtotal.toFixed(2) - couponDetail.couponamount) : _.toString(subtotal.toFixed(2))),
                  localStorage.getItem('currencyName')!=null?localStorage.getItem('currencyName'):'INR',
                  'card')
                return
              }
        
              if(enableOnlinePayment && !isSucceeded){
                alert('Payemnt Failed')
                return
              }
            }
            navigate("/Order")
            // navigate("/ViewOrder/"+data.organiserId);
    
            if (response.data && response.data.length > 0) {
              setData(response.data);
            } else {
              setData([]);
            }
            setLoading(false);
            if (data.token) {
              localStorage.setItem("token", token);
            }
          } else {
            console.error("POST request failed");
          }
        } catch (error) {
          console.error("Error posting data:", error);
          setLoading(false);
        }
  }else {
    alert("Product Out of Stock")
    navigate("/Cart");
  }
 } catch (error) {
      console.error("Error fetching or navigating:", error);
  }
  };



  const setShowEditBillingAddressFunction = () => {
    setShowEditBillingAddress(true);
  };
  const handleRemoveCoupon = async () =>{
    setCouponDetail({});
    setCouponInputDisabled(false);
    notification.success({
      message: 'Coupon Removed',
      description: 'Coupon has been removed.',
      duration: 3, // Duration in seconds
    });
  }
  const handleshowCoupon = async () => {
    const clientId = localStorage.getItem("clientid");

    try {
      const formData = new FormData();
      formData.append("clientid", clientId);
      formData.append("ordervalue", parseFloat(subtotal.toFixed(2)).toString());

      const response = await fetch(
        "https://api.starmallonline.com/api/v1/coupon/available",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCoupons1(data);
        setModalVisibleCoupon(true);
      } else {
        console.error("Failed to apply coupon");
      }
    } catch (error) {
      console.error("Error applying coupon:", error);
    }
  };
  const handleCouponmodalselect = (data) =>{
    setCouponDetail(data);
      setCouponResponse(null);
      setCouponInputDisabled(true);
      setModalVisibleCoupon(false); 
      notification.success({
        message: 'Coupon Applied',
        description: 'Coupon has been successfully applied.',
        duration: 3, // Duration in seconds
      });
  }
  const handleApplyCoupon = async () => {
    const clientId = localStorage.getItem("clientid");
    if (!couponcode) {
      notification.success({
        message: 'Please enter a coupon code',
        duration: 2, // Duration in seconds
      });
      return;
    }
    try {
      const formData = new FormData();
      formData.append("clientid", clientId);
      formData.append("couponcode", couponcode);
      formData.append("ordervalue", parseFloat(subtotal.toFixed(2)).toString());

      const response = await fetch(
        "https://api.starmallonline.com/api/v1/coupon/check",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("text")) {
          const data = await response.text();
          setCouponResponse(data);
        } 
        else{
          const data = await response.json();
        setCouponDetail(data);
          setCouponResponse(null);
          setCouponInputDisabled(true);
        }
      } else {
        console.error("Failed to apply coupon");
      }
    } catch (error) {
      console.error("Error applying coupon:", error);
    }
  };
  const handleCancelModel = () => {
    setModalVisibleCoupon(false);
  }
  const handleCheckboxClick = async (country,state,pincode) => {
    if (!showBillingAddress) {
      // Fetch addressBilling data or perform any other action
      try {
        const response = await fetch('https://api.starmallonline.com/api/v1/pincode/check', {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ 
            country:country,
            state:state,
            pincode: pincode ,
            currency: localStorage.getItem("currencyName"),
          }), // Correctly include pincode in the request body
        });
        if (response.ok) {
          const data = await response.json();
          if(pincodeCheck && pincodeCheck.pincode==data.pincode){
            setPincodeCheck({});
          }else{
            
            setPincodeCheck(data);
          }
          console.log('Pincode Check response:', data);
        } else {
          console.error("Failed to check pincode");
        }
      } catch (error) {
        console.error("Error checking pincode:", error);
      }
    }
  };
  const handleEditBillingAddress = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };
  const handleReloadBilling = async () => {
    try {
      const response = await fetch(
        "https://api.starmallonline.com/api/v1/client/getbillingaddress",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            // Include any necessary parameters for fetching billing address data
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setBillingAddress(data);
      } else {
        console.error("Failed to reload billing address");
      }
    } catch (error) {
      console.error("Error reloading billing address:", error);
    }
  };
  const storedNavigationState = localStorage.getItem("navigateState");
  useEffect(() => {
    // Check if navigationState exists in localStorage
    const storedNavigationState = localStorage.getItem("navigateState");
    if (storedNavigationState) {
      // Parse and set navigationState
      setNavigationState(JSON.parse(storedNavigationState));
    } else {
      // If not found in localStorage, navigate back one page
      navigate(-1);
    }
  }, []);
  
  return (
    <div className={clientSecret ? "h-screen w-screen flex items-center justify-center" : ""}>
      {!clientSecret && <div>
          <div>
            <Helmet>
              <title>Checkout</title>
            </Helmet>
          </div>
          <Ecomheader />
          <main className="main checkout  container homebg">
            <div className="page-content pt-7 pb-10 mb-10">
              <div className="step-by pr-4 pl-4">
                <h3 className="title title-simple title-step">
                  <Link to="/Cart">1. Cart</Link>
                </h3>
                <h3 className="title title-simple title-step active">
                  <Link to="/Checkout">2. CheckOut</Link>
                </h3>
                <h3 className="title title-simple title-step">
                  {" "}
                  {/* <Link to="/Order">3. Order Complete</Link> */}
                  3. Complete
                </h3>
              </div>
              <div className="container-fluied mt-7">
                {/* Shipping Details */}

                <div className="row">
                  <div className="col-md-8   col-sm-12">
                    <form action="#" className="form" onSubmit={onFinish}>
                      <div className="container-fluied">
                        <h3 className="title title-simple text-left text-uppercase address-title">
                          Billing Details
                        </h3>
                        
                        {loading ? (
  <div className="container-fluid">
  <div className="row d-flex justify-content-center">
    <div class="spinner"></div>
  </div>
</div>
) : (
                        <div className="container-fluid">
                          {!billingaddress ||
                            Object.keys(billingaddress).length === 0 ||
                            showEditBillingAddress ? (
                            <OnlyBilling billingaddress={billingaddress} />
                          ) : (
                            <div className="col-xs-12">
                              <label className="capitalize">
                                {billingaddress.name}<br /><br />
                                {billingaddress.address1},{billingaddress.address2}<br /><br />
                                {billingaddress.city},{billingaddress.pincode},{billingaddress.phonenumber}
                              </label>
                              <Link onClick={() => setShowEditBillingAddressFunction()} className="btn btn-link btn-secondary btn-underline scale-90 my-2" >
                                Edit <i className="far fa-edit"></i>
                              </Link> 
                            </div>
                          )}
                        </div>
)}
                        <div className="row">
                          <div className="col-md-12 col-sm-12 address-title d-flex justify-content-between">
                            <h3 className="title title-simple text-left text-uppercase ">
                              Shipping Address 
                            </h3>
                           
                            
                          </div>
                        </div>
  <div className="row mt-5">
    <div className="col-md-6 col-sm-12">
      <div className="container-fluid row">
      <div className="col-md-2 col-sm-2">
        <div className="form-checkbox d-flex align-items-center shippingaddress-checkbox">
                                  <div className="radio-set">
                                  <input
                                    type="radio"
                                    className="custom-radio"
                                    id="same-as-billing"
                                    name="addressType"
                                    onChange={() =>{
                                      setshowBillingAddress(!showBillingAddress)
                                      setSameAsBilling(true)
                                    }}
                                    onClick={() => handleCheckboxClick(billingaddress.country,billingaddress.state,billingaddress.pincode)}
                                  />
                                  <label
                                    className="form-control-label ls-s"
                                    for="same-as-billing"
                                  >
                                  </label>
                                  </div>
        </div>
        </div>
        <div className="col-md-10 col-sm-10 d-flex align-items-center">  
                                  <div className="termsandconditions align-self-center">
                                    Same as Billing Address
                                    </div>
        </div>
        </div>
      </div>
      <div className="form-radio col-md-12 col-sm-12">
        <div className="row">
          {addressBilling &&
            addressBilling.map((address, index) => (
              <div
                className="col-md-6 col-sm-12"
                key={address.clientaddressid}
              >
                <div className="form-radio-item">
                <div className="container-fluid row">
                  <div className="col-md-2 col-sm-2">
                <div className="radio-set mt-1">
                  <input
                    type="radio"
                    className="custom-radio"
                    id={`radio-${index}`}
                    name="addressType"
                    onClick={() => handleCheckboxClick(address.country,address.state,address.pincode)}
                  />
                  <label
                    className="form-control-label ls-s"
                    htmlFor={`radio-${index}`}
                  ></label>
                  </div>
                  </div>
                  <div className="col-md-10 col-sm-10 m-0 p-0">
                    <div className="termsandconditions align-self-center">
                                            <p>
                    {address.name},{" "}
                    {address.companyname},{" "}
                    {address.country},{" "}
                    {address.city}, {address.state}
                    ,  {address.pincode},{address.phonenumber}, {" "}
                    {address.email}
                    </p>
                    </div>
                  
                  </div>
                  </div></div>
                  </div>
              
            ))}
        </div>
      </div>
      <Link
        to="/ShippingForm"
        className="btn btn-link btn-secondary btn-underline scale-90 my-2"
      >
        ADD+
      </Link>
    
  </div>


                        {/* {!showBillingAddress && (
                          <div className="row mt-5">
                            <div className="col-xs-12">
                              <div className="form-radio">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox"
                                  id="different-address"
                                  name="different-address"
                                />
                                <div className="row">
                                  {addressBilling &&
                                    addressBilling.map((address, index) => (
                                      <div
                                        className="col-md-6"
                                        key={address.clientaddressid}
                                      >
                                        <div className="form-checkbox">
                                        <div className="checkbox-set mt-1">
                                          <input
                                            type="checkbox"
                                            className="custom-checkbox"
                                            id={`checkbox-${index}`}
                                            onClick={() => handleCheckboxClick(address.country,address.state,address.pincode)}
                                          />
                                          <label
                                            className="form-control-label ls-s"
                                            htmlFor={`checkbox-${index}`}
                                          >
                                          </label>
                                          </div>
                                          <div className="termsandconditions align-self-center">
                                            <p>
                                              {address.name},{" "}
                                              {address.companyname},{" "}
                                              {address.country},{" "}
                                              {address.city}, {address.state}
                                              ,  {address.pincode},{address.phonenumber}, {" "}
                                              {address.email}
                                            </p></div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                              <Link
                                to="/ShippingForm"
                                className="btn btn-link btn-secondary btn-underline scale-90 my-2"
                              >
                                ADD+
                              </Link>
                            </div>
                          </div>
                        )} */}
                        <h3 className="title title-simple text-left text-uppercase address-title mt-5">Additional Information </h3>
                        <label>Order Notes (Optional)</label>
                        <textarea
                          className="form-control"
                          cols="30"
                          rows="5"
                          placeholder="Notes about your order, e.g. special notes for delivery"
                        ></textarea>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-4">
                    <form action="#" className="form" onSubmit={(e) =>{
                      e.preventDefault();
                      onFinished()
                    }}>
                      <div
                        className="sticky-sidebar mt-1"
                        data-sticky-options="{'bottom': 50}"
                      >
                        <div className="summary">
                        <h3 className="summary-title text-left mb-3">
                            Your Order
                          </h3>
                          <div className="container-fluied"></div>
                          <div className="row" key={navigationState.productDetails.productid}>
                          <div className="col-md-2 col-sm-2">
                          <img src={navigationState.productDetails.previewimg.file} alt={navigationState.productDetails.productname}  width={50} height={50} />
                          </div>
                          <div className="col-md-6 col-sm-10 product-description">{navigationState.productDetails.productname}{" "}  <span className="product-quantity">
                                      × {navigationState.quantity} qty
                                    </span></div>
                          <div className="col-md-4 col-sm-12 text-right product-prict p-0 m-0">{localStorage.getItem('currencySymbol')} {parseFloat(
                                      navigationState.productDetails.sellingprice * navigationState.quantity
                                    ).toFixed(2)}</div>
                          </div>
                          <table className="order-table">
                          
                            <tbody>
                               
                              <tr className="summary-subtotal">
                                <td>
                                  <h4 className="summary-subtitle">Subtotal</h4>
                                </td>
                                <td className="summary-subtotal-price pb-0 pt-0">
                                {localStorage.getItem('currencySymbol')}{subtotal.toFixed(2)}
                                </td>
                              </tr>
                              {pincodeCheck && parseFloat(pincodeCheck.cartvalue) > parseFloat(_.toString(subtotal.toFixed(2))) &&
                              <tr className="summary-subtotal">
                                <td>
                                  <h4 className="summary-subtitle">Delivery Charges</h4>
                                </td>
                                <td className="summary-subtotal-price pb-0 pt-0">
                                {localStorage.getItem('currencySymbol')}{pincodeCheck.shippingrate}
                                </td>
                              </tr>
                              }
                              <tr className="sumnary-shipping shipping-row-last"></tr>
                              <tr className="summary-total">
                                <td className="pb-0">
                                  <h4 className="summary-subtitle">Total</h4>
                                </td>
                                <td className=" pt-0 pb-0">
                                  <p className="summary-total-price ls-s text-primary">
                                  {localStorage.getItem('currencySymbol')} {pincodeCheck && pincodeCheck.shippingrate && pincodeCheck && parseFloat(pincodeCheck) > parseFloat(_.toString(subtotal.toFixed(2)))? parseFloat((couponDetail.couponamount !=null && couponDetail.couponamount ?_.toString(subtotal.toFixed(2) - couponDetail.couponamount) : _.toString(subtotal.toFixed(2)))) + parseFloat(pincodeCheck.shippingrate):(couponDetail.couponamount !=null && couponDetail.couponamount ?_.toString(subtotal.toFixed(2) - couponDetail.couponamount) : _.toString(subtotal.toFixed(2)))}
                                  </p>
                                </td>
                              </tr>

                              {/* <tr>
                             {!couponInputDisabled &&
                                <td>
                              <h4 class="summary-subtitle ">Coupon</h4>
                              </td>
}
                              <td>
                              {!couponDetail.couponamount &&
                              <div  className="" onClick={handleshowCoupon}>View</div>
}
                              </td>
                              </tr> */}
                              {/* <tr>
                              {couponDetail.couponamount  ? (<>
                              <td>
                              <div >Coupon Amount : {couponDetail.couponamount}</div>
                              </td>
                              <td>
                              <div className="cart-btn" onClick={handleRemoveCoupon}>Remove</div>
                              </td></>
                              ) :
                              
                              (
                                <td>
                              <input
                        type="text"
                        className="form-control"
                        id="register-firstname"
                        name="register-firstname" 
                        placeholder="Please Enter Coupon Code"
                        value={couponcode}
        onChange={(e) => setCouponCode(e.target.value)}
        disabled={couponInputDisabled}
                      />
                      </td>)}
                       {!couponInputDisabled && (
                        <td>
                      <div className="checkout-cart-btn" onClick={handleApplyCoupon}>Apply</div>
                      </td>)}
                      {couponResponse && (
        <p className="coupon-response text-center">{couponResponse}</p>
      )}
      </tr> */}
                              <tr class="sumnary-shipping shipping-row-last">
                                <td colspan="2">
                                  <h4 class="summary-subtitle">Payment Methods</h4>
                                  <ul>
                                    <li>
                                      <div class="custom-radio">
                                        <input
                                          type="radio"
                                          id="flat_rate"
                                          name="shipping"
                                          class="custom-control-input"
                                          onClick={() => setEnableOnlinePayment(true)}
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="flat_rate"
                                        >
                                          Online Payments
                                        </label>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="custom-radio">
                                        <input
                                          type="radio"
                                          id="local_pickup"
                                          name="shipping"
                                          class="custom-control-input"
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="local_pickup"
                                        >
                                          Cash On Delivery
                                        </label>
                                      </div>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <button
                              type="submit"
                              className="btn btn-dark btn-rounded btn-order"
                            >
                              Place Order
                            </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Ecomfooter />
        </div>}
        
<Modal
  title="Select Coupon"
  visible={modalVisibleCoupon}
  width={1000} // Set the desired width here
  footer={null}
  onCancel={handleCancelModel}
>{coupons1 && coupons1.length > 0 ? (
  <>
    <div style={{display:'flex'}}>
      <p className="col-md-2 col-sm-3"><strong>Name</strong></p>
      <p className="col-md-2 col-sm-3"><strong>Code</strong></p>
      <p className="col-4 coupondescription"><strong>Description</strong></p>
      <p className="col-md-2 col-sm-3"><strong>Discount</strong></p>
      <p className="col-md-2 col-sm-3"><strong>Action</strong></p>
    </div>
    {coupons1.map((coupon) => (
      <div  key={coupon.couponid} style={{ marginBottom: '20px',display:'flex' }}>
        <p className="col-md-2 col-sm-3"><strong>{coupon.couponname}</strong></p>
        <p className="col-md-2 col-sm-3"><strong>{coupon.couponcode}</strong></p>
        <p className="col-4 coupondescription"><strong>{coupon.coupondescription}</strong></p>
        <p className="col-md-2 col-sm-3"><strong>{coupon.couponamount}</strong></p>
        <p className="col-md-2 col-sm-3" onClick={()=>{
          handleCouponmodalselect(coupon)
        }}><div className="checkout-cart-btn">Apply</div></p>
      </div>
    ))}
  </>
) : (
  <p>No coupons available</p>
)}
</Modal>
<Modal
  title={"BILL VALUE "+ordertotalfinal}
  className="col-md-12  paymentmodelstyling"
  footer={null}
  closable={false} 
visible={clientSecret!=null}>
      {clientSecret && <Stripe orderData={orderDAta1}  clientSecret = {clientSecret} isSucceeded = {(value) => {
        setIsSucceeded(value);
        setClientSecret(null, () => {
          onFinished();
        });
      }} />}</Modal>
{/* <Modal
        title="Edit Billing Address"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <BillingAddressModal
          billingaddress={billingaddress}
          closeModal={closeModal}
          reloadBilling={fetchBillingAddress}
        />
      </Modal> */}

    </div>
  );
};
export default BuyCheckout;
