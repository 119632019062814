import React from "react";    
import { useLocation } from 'react-router-dom';
import MobileMenu from "../../src/navbarHead/mobileNav";
import { Helmet } from "react-helmet";
import Ecomheader from '../../src/header/headershop';
import Ecomfooter from '../footer/footershop';
import ProductWrap from "../../src/product/productWrap";
const SearchItems = () => {
    const location = useLocation();
    const searchData = location.state && location.state.searchData;
    const errorDescription = searchData && searchData.status === "Error" ? searchData.description : null;

    return (
        <div>
            <div>
                <Helmet></Helmet>
            </div>
            <Ecomheader />
            <MobileMenu />
            <div className="row">
                
                    <div className="container homebg ">
                        {errorDescription ? (
                            <div className="row">
                                <div className="col-12 text-center">
                  <h1>No products available for this category.</h1>
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                {searchData && searchData.map((product, index) => (
                                    // <div key={product.productid} className="col-xs-6 col-sm-2 col-lg-2 col-md-2">
                                        <ProductWrap key={product.productid} product={product} />
                                    // </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <Ecomfooter />
            </div>
        </div>
    );
};

export default SearchItems;




