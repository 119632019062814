import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { deleteCart, editCart } from "../Redux/Redux";
import { Modal, Button } from 'antd';
const Cart = () => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [TotalproductData, setTotalProductData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productDatawithQuantity, setProductDatawithQuantity] = useState([]);
  const [productDatawithoutQuantity, setProductDatawithoutQuantity] = useState([]);
  const [productDataforQuantity, setproductDataforQuantity] = useState([]);
  const [totalSubtotal, setTotalSubtotal] = useState(0);
  const [carts, setCart] = useState(JSON.parse(localStorage.getItem('demo')) || []);
  const price = 122.99;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisibleforQuantity, setIsModalVisibleforQuantity] = useState(false);
  const [modalMessageforQuantity, setModalMessageforQuantity] = useState('');

  const cart = useSelector((state) => state.cart.value);
  const dispatch = useDispatch()
  const currencyType = localStorage.getItem('currencyName') != null ? localStorage.getItem('currencyName') : "";

  const cartData = cart;

  // Create an array of just the product IDs
  const productIds = cartData.map(item => item.productid);

  // Convert the product IDs to a comma-separated string
  const productIdsString = productIds.join(',');

  // Create a FormData object and append the productIds
  const formData = new FormData();
  formData.append("productIds", productIdsString);
  formData.append('currency', currencyType);
  const fetchProductData = async () => {
    try {
      setIsLoading(true)
      setproductDataforQuantity([]);
      setProductDatawithQuantity([]);
      setProductDatawithoutQuantity([]);
      // Retrieve productIds from localStorage

      const response = await fetch(
        `https://api.starmallonline.com/api/v1/product/getByIdarray`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (response.ok) {
        const productData = await response.json();
        if (!productData.description) {
          setTotalProductData(productData);
          const quantityZeroProducts = productData.filter(product => product.quantity == 0);
          const quantityGreaterThanZeroProducts = productData.filter(product => product.quantity > 0);
          setProductDatawithQuantity(quantityGreaterThanZeroProducts);
          const productDataWithzeroQuantity = quantityZeroProducts.map(product => ({
            ...product,
            cartid: cartData.find(item => item.productid === product.productid)?.cartid
          }));
          setProductDatawithoutQuantity(productDataWithzeroQuantity);
          setproductDataforQuantity(quantityGreaterThanZeroProducts)
          // Make sure that productData is an array of products
          if (Array.isArray(quantityGreaterThanZeroProducts)) {
            // Set the initial quantity for each product based on cart data
            const productDataWithQuantity = quantityGreaterThanZeroProducts.map(product => ({
              ...product,
              quantity: cartData.find(item => item.productid === product.productid)?.productquantity || 1,
              cartid: cartData.find(item => item.productid === product.productid)?.cartid
            }));

            setProductData(productDataWithQuantity);
          }
          return await response.json();
        } else {
          setTotalProductData([]);
          setProductDatawithQuantity([]);
          setProductDatawithoutQuantity([]);
          setproductDataforQuantity([]);
          setProductData([]);
        }
      } else {
        console.error("Failed to fetch product data");
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true)
    fetchProductData();
  }, [cart]);


  useEffect(() => {


    // Function to fetch client details to check authentication
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          'https://api.starmallonline.com/api/v1/login/getclientdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        if (response.ok) {
          setIsAuthenticated(true);
          // fetchCartDetails();
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);


  const handleIncrement = () => {
    // Increment the quantity by 1
    setQuantity(quantity + 1);
  };

  // Function to handle decrementing the quantity
  const handleDecrement = () => {
    // Ensure the quantity does not go below 1
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };
  React.useEffect(() => {
    const newSubtotal = price * quantity;
    setSubtotal(newSubtotal);
  }, [price, quantity]);

  const handleCheckout = async () => {
    try {
      await fetchProductData();
      const response = await fetch(`https://api.starmallonline.com/api/v1/product/checkforquantity`, {
        method: "PUT",
        body: formData,
      });
      const qtyCheck = await response.json();

      if (qtyCheck.organiserId === "true") {
        navigate("/Checkout", { state: { productData } });
        const token = localStorage.getItem("token");
        if (token && token !== "null") {
          // Token is present and not null, proceed with checkout
          localStorage.setItem("productData", JSON.stringify(productData));
        } else {

          setIsPopupVisible(!isPopupVisible);

        }
        // Save productData to localStorage
        localStorage.setItem("productData", JSON.stringify(productData));
      }
    } catch (error) {
      console.error("Error fetching or navigating:", error);
    }
  };

  // Function to update the quantity for a specific product
  const updateProductQuantity = async (productId, cartid, newQuantity) => {
    fetchProductData();
    const isProductMatched = productDataforQuantity.find(product => product.productid === productId);
    if (isProductMatched && isProductMatched.quantity >= newQuantity) {
      dispatch(editCart({ productId, cartid, newQuantity }));
    } else {
      setModalMessageforQuantity(`Exceed minimun Value`);
      setIsModalVisibleforQuantity(true);
    }
  };




  useEffect(() => {
    // Calculate the total subtotal when productData or quantities change
    const totalSub = productData.reduce((total, product) => {
      return total + product.sellingprice * product.quantity;
    }, 0);

    setTotalSubtotal(totalSub);
  }, [productData]);
  // Function to update the cart icon value
  const updateCartIconValue = (newQuantity) => {
    setQuantity(newQuantity);
  };


  //function to remove item from cart 
  const handleRemoveProduct = async (cartid) => {
    const confirmed = window.confirm("Are you sure you want to remove this product?");
    if (confirmed) {
      dispatch(deleteCart(cartid))
    }
  };


  const removeItFromLocalStorage = (productId, cartid) => {
    // Find the index of the product to be removed
    const productIndex = productData.findIndex(
      (product) => product.productid === productId
    );

    // If the product is found, remove it from the productData array
    if (productIndex !== -1) {
      const updatedProductData = [...productData];
      updatedProductData.splice(productIndex, 1);
      setProductData(updatedProductData);

      // Remove the product from local storage as well
      const cartData = JSON.parse(localStorage.getItem("cart")) || [];
      const updatedCartData = cartData.filter(
        (product) => product.productid !== productId
      );
      localStorage.setItem("cart", JSON.stringify(updatedCartData));
      // Update the cart icon value
      updateCartIconValue(updatedCartData.length);
    }
  };



  return (

    <div>

      <div>
        <Helmet>
          <title>Cart</title>
        </Helmet>
      </div>
      <Ecomheader />
      <main className="main cart container homebg">
        {isLoading ? (
          <div className="container-fluied">
            <div className="row">
              <div className="loader"></div>
            </div>
          </div>
        ) : (
          <div className="page-content pt-7 pb-10">
            {isAuthenticated && productData.length > 0 && (
              <div className="step-by pr-4 pl-4">
                <h3 className="title title-simple title-step active">
                  <Link to="/Cart">1. Cart</Link>
                </h3>
                <h3 className="title title-simple title-step">
                  {isAuthenticated && productData.length > 0 ? (
                    <Link to="/Checkout">2. CheckOut</Link>
                  ) : (
                    <span>2. CheckOut</span>
                  )}
                </h3>
                <h3 className="title title-simple title-step">
                  {/* {isAuthenticated ? (
    <Link to="/Order">3. Order Complete</Link>
  ) : (
    <span>3. Order Complete</span>
  )} */}
                  <span>3. Complete</span>
                </h3>
              </div>
            )}
            {!isAuthenticated ? (

              <div className="container-fluied">
                <div class="card">
                  <div class="alert alert-light alert-primary alert-icon mb-4 card-header">
                    <i class="fas fa-exclamation-circle"></i>
                    <span class="text-body">Returning customer?</span>
                    <Link to="/LoginPage" class="  " style={{ color: "#000" }} >Click here to login </Link>
                    OR else
                    <Link to="/LoginPage" class=" " style={{ color: "#000" }} >create a new account</Link>
                  </div>
                </div>
              </div>
            ) : (
              productData.length === 0 ? (
                <div className="">
                  {/* <img src="https://storage.shopeasey.com/nocart.jpg" alt="test" className="rounded mx-auto d-block" /> */}
                  <Link to="/HomePage">
                    <b className="text-center"><p>Your cart is empty, Please continue shopping.</p></b>
                  </Link>
                </div>


              ) : (
                <div className="container-fluied mt-7 mb-2">



                  <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12 pr-lg-4">
                      <div className="container-fluied">
                        {productData.map((product) => (
                          <div className="row cart-row" key={product.productid}>

                            <div className="col-lg-12  col-sm-12 col-md-12">
                              <div className="row">
                                <div className="col-lg-2  col-sm-3 col-md-2">
                                  <Link
                                    to={`/Product/${product.productid}`}
                                    key={product.productid}
                                    onClick={() => localStorage.setItem("productid", product.productid)}
                                  >
                                    <figure>
                                      <img
                                        src={product.previewimg.file}
                                        alt={product.productname}
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                          objectFit: "contain",
                                          overflow: "hidden"
                                        }}
                                      />
                                    </figure>
                                  </Link>
                                </div>
                                <div className="col-lg-6  col-sm-9 col-md-6">
                                <Link
                                to={`/Product/${product.productid}`}
                                key={product.productid}
                                onClick={() => localStorage.setItem("productid", product.productid)}
                              >
                                <p className="product-name">{product.productname}</p>
                                 
                              </Link>
                              
                              <span className="amount">
                                {product.currency === 'SGD' ? 'S$' :
                                  product.currency === 'MYR' ? 'RM' :
                                    product.currency === 'INR' ? '₹' :
                                      product.currency}  {product.sellingprice}
                              </span>
                              <span className="amount text-red-500 opacity-70 mrp-price" style={{color:"var(--rm-red)"}}>
                                {product.currency === 'SGD' ? 'S$' :
                                  product.currency === 'MYR' ? 'RM' :
                                    product.currency === 'INR' ? '₹' :
                                      product.currency}  {product.mrpprice}
                              </span>
                              
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-2  col-sm-2 col-md-2"></div></div>
                            </div>

                             
                            <div className="col-lg-4 col-sm-6 col-md-7">
                              
                              <a
                                href="#"
                                className="product-remove"
                                title="Remove this product"
                                onClick={() => { handleRemoveProduct(product.cartid) }} // Call the function when "X" is clicked
                              >
                                Cancel
                              </a>
                            </div>

                            <div className="col-lg-2  col-sm-3 col-md-3 ">
                              
                              <div className="flex items-center cart-qty">
                                <span
                                  className="cart-qty-btn"
                                  onClick={() => {
                                    updateProductQuantity(
                                      product.productid,
                                      product.cartid,
                                      parseInt(product.quantity) - 1
                                    )
                                  }}
                                >{product.quantity > 1 ? '-' : ''}</span>
                                <span className="cart-qty-value">
                                  {product.quantity}
                                </span>

                                <span
                                  className="cart-qty-btn "
                                  onClick={() =>
                                    updateProductQuantity(
                                      product.productid,
                                      product.cartid,
                                      parseInt(product.quantity) + 1
                                    )
                                  }
                                >+</span>
                              </div>
                            </div>


                            {/* <div className="col-lg-2  col-sm-3 col-md-2  text-right">
                          <span className="amount">
                                  {localStorage.getItem('currencySymbol')} {(product.sellingprice * product.quantity).toFixed(2)}
                           </span>

                          </div> */}

                          </div>
                        ))}
                      </div>

                    </div>
                    <aside className="col-lg-4 col-sm-12 justify-content-end  mt-5">
                      <div className="sticky-sidebar" data-sticky-options="{'bottom': 20}" >
                        <div className="summary mb-4">
                          <h3 className="summary-title text-left">Cart Totals</h3>
                          <table className="shipping">
                            <tr className="summary-subtotal">
                              <td>
                                <h4 className="summary-subtitle">Subtotal</h4>
                              </td>
                              <td>
                                <p className="summary-subtotal-price">
                                  {localStorage.getItem('currencySymbol')}{totalSubtotal.toFixed(2)}
                                </p>
                              </td>
                            </tr>
                          </table>
                          <table className="total">
                            <tr className="summary-subtotal">
                              <td>
                                <h4 className="summary-subtitle">Total</h4>
                              </td>
                              <td>
                                <p className="summary-total-price ls-s">
                                  {localStorage.getItem('currencySymbol')} {totalSubtotal.toFixed(2)}
                                </p>
                              </td>
                            </tr>
                          </table>
                          <div>

                          </div>


                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        {isAuthenticated && productData.length > 0 && (!productDatawithoutQuantity || productDatawithoutQuantity.length == 0) ? (
                          <div
                            className="btn btn-dark btn-rounded btn-order"
                            onClick={handleCheckout}
                          >
                            Proceed to Checkout
                          </div>
                        ) : (
                          <p className="message">
                          </p>
                        )}
                      </div>
                    </aside>

                  </div>
                </div>
              )
            )}
          </div>
        )}
      </main>
      <Modal
        visible={isModalVisibleforQuantity}
        onOk={() => setIsModalVisibleforQuantity(false)}
        onCancel={() => setIsModalVisibleforQuantity(false)}
        footer={[
          <Button key="ok" type="primary" onClick={() => setIsModalVisibleforQuantity(false)}>
            OK
          </Button>,
        ]}
      >
        {modalMessageforQuantity}
      </Modal>
      <Ecomfooter />
    </div>
  );
}
export default Cart;